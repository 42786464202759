import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/core/auth.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-session-rating2',
  templateUrl: './session-rating2.component.html',
  styleUrls: ['./session-rating2.component.scss']
})
export class SessionRating2Component implements OnInit {
  @ViewChild('list', { static: true }) list: ElementRef;

  data: {
    accountId: string,
    userId: string,
    guest: boolean,
    role: string,
    roomId: string,
    sessionId: string
  } = null;

  questions = []
  allQuestionsRated = false

  secondLogo

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private authService: AuthService,
    private loaderService: LoaderService,
    private flashMessage: FlashMessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (!(navigation && navigation.extras && navigation.extras.state)) {
      this.router.navigate(['/']);
    } else {
      this.data = <any>navigation.extras.state;
    }
  }

  ngOnInit(): void {
    if (!this.data) {
      this.router.navigate(['/']);
    } else if (this.data.role === 'expert') {
      this.questions = [
        { key: "IS_TOOL_ACCURATE", rate: 0 }
      ]
    } else {
      this.questions = [
        { key: "SUPPORT_HELPFUL", rate: 0 },
        { key: "CLEAR_AND_EASY", rate: 0 },
        { key: "IS_RESOLVED", rate: 0 },
        { key: "USE_IN_FUTURE", rate: 0 },
      ]
    }

    this.authService.getSecondLogo().then(data => {
      if (data && data.show) {
        this.secondLogo = data.url;
      }
    });
  }

  onQuestionRating(index, rate) {
    this.questions[index].rate = rate

    if (this.questions.every(question => question.rate > 0)) {
      this.allQuestionsRated = true
    }

    if (index === this.questions.length - 1) {
      const el = this.list.nativeElement;
      this.renderer.setProperty(el, "scrollTop", el.scrollHeight);  
    }
  }

  onSubmitRates() {
    const rates = this.questions.reduce((acc, qu) => { acc[qu.key] = qu.rate; return acc; }, {})

    const body = {
      accountId: this.data.accountId,
      userId: this.data.userId,
      role: this.data.role,
      guest: this.data.guest,
      roomId: this.data.roomId,
      sessionId: this.data.sessionId,
      rates: rates
    }

    this.loaderService.show();
    this.authService.sendRatingData2(body)
      .then(() => {
        if (this.data.guest) {
          this.router.navigate(['/login']);
          location.reload();
        } else {
          this.router.navigate(['/']);
        }
        this.loaderService.hide();
      })
      .catch(error => {
        this.flashMessage.showTranslated("APP.MAIN.SESSION_RATING2.ERROR", { cssClass: 'alert-danger', timeout: 5000 });
        this.loaderService.hide();
      })
  }
}
