import { Component, EventEmitter, Output, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Room } from '@models/Room';
import { User } from '@models/User';
import { AuthService } from '@services/core/auth.service';

import { RoomSessionService } from '@services/core/room-session.service';
import { UtilityService } from '@services/support/utility.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { combineLatest, Subscription } from 'rxjs';
import { TicketsModalComponent } from '../../lobby/tickets-modal/tickets-modal.component';
import { TicketDetailComponent } from '../../lobby/tickets/ticket-detail/ticket-detail.component';
import { WorkflowListComponent } from '../../lobby/workflow-list/workflow-list.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Output() unreadMessage = new EventEmitter<boolean>();
  @Output() workflowDataChanged = new EventEmitter<boolean>();

  // Tab visibilities, first one visible at start
  // tabs: boolean[] = [true, false, false, false, false];
  currentTab: string = 'chat';

  rTranslationAvailable: boolean = false;

  sessionActive: boolean = false;
  sessionActiveSub: Subscription = null;
  
  currentUser: User = null;

  filesSectionAvailable: boolean = true; 
  featuresSub: Subscription = null;

  attachedTicketId: string = null;
  selectedTicket = null;
  ticketsAvailable: boolean = false;

  workflowAvailable: boolean = false;
  workflowTicketClass: any = {}

  roomSub: Subscription = null;
  currentRoom: Room;
  sessionDataSub: Subscription = null;

  sessionExportEnabled: boolean = false;
  webdavEnabled: boolean = false;
  roomFilesSubscription: boolean = false

  constructor(
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private utilityService: UtilityService,
    private modal: BsModalService,
  ) { }

  ngOnInit() {
    this.sessionActiveSub = this.roomSessionService.sessionActive
    .subscribe(active => {
      this.sessionActive = active;
    });

    this.featuresSub = combineLatest([this.authService.features, this.authService.addOns, this.authService.license, this.utilityService.getServerTimeOffset()])
    .subscribe(([features, addOns, license, timezoneOffset]) => {
      this.filesSectionAvailable = features.roomfiles;
      this.ticketsAvailable = addOns.ticket && this.currentUser?.role === 'expert';
      this.sessionExportEnabled = addOns.sessionexport;
      this.webdavEnabled = addOns.webdav;
      this.roomFilesSubscription = addOns.roomfilesforsubs;
      this.rTranslationAvailable = addOns.realtimetranslation;

      const now = (new Date()).getTime() - timezoneOffset;
      const twelveHoursThreshold = 1000*60*60*12;
      const workflowAccount = license.workflow_account;
      const workflowLicenseStarted = license.workflow_start && (license.workflow_start - twelveHoursThreshold < now);
      const workflowLicenseNotEnded = license.workflow_end && (license.workflow_end + twelveHoursThreshold > now);

      this.workflowAvailable = workflowAccount && workflowLicenseStarted && workflowLicenseNotEnded && addOns.workflowonremote;

      if (this.ticketsAvailable) {
        if (this.workflowAvailable) {
          this.workflowTicketClass = { "show-workflow-and-ticket": true };
        } else {
          this.workflowTicketClass = { "show-only-ticket": true };
        }
      } else {
        if (this.workflowAvailable) {
          this.workflowTicketClass = { "show-only-workflow": true };
        } else {
          this.workflowTicketClass = {};
        }
      }
    });

    this.roomSub = this.roomSessionService.currentRoom.subscribe(room => {
      this.currentRoom = room;
    });
    this.sessionDataSub = this.roomSessionService.sessionData.subscribe(sessionData => {
      this.attachedTicketId = sessionData.attached_ticket;
    });
    this.currentUser = this.authService.currentUser;
  }

  ngOnDestroy() {
    if (this.sessionActiveSub) { this.sessionActiveSub.unsubscribe() }
    if (this.roomSub) { this.roomSub.unsubscribe() }
    if (this.sessionDataSub) { this.sessionDataSub.unsubscribe() }
    if (this.featuresSub) { this.featuresSub.unsubscribe() }
  }


  onTicketModal(){
    if(this.attachedTicketId){
      const initialState= {
        mode: 'detail',
        ticketId: this.attachedTicketId,
        roomSession: true,
        currentRoom: this.currentRoom
      };
      this.modal.show(TicketDetailComponent,  {initialState, backdrop:'static', class: 'white modal-lg', animated:false});
    }else{
      const initialState= {
        currentRoom: this.currentRoom
      };
      this.modal.show(TicketsModalComponent,  {initialState, backdrop:'static', class: 'white modal-lg', animated:false});
    }
  }

  openWorkflow(){
    let workflowListModal = this.modal.show(WorkflowListComponent,  { backdrop:'static', class: 'white modal-lg', animated:false});
    workflowListModal.content.onClose.subscribe(res => {
      this.workflowDataChanged.emit(res)
    })
  }

  onTabClicked(tabName: string) {
    this.currentTab = tabName;
    
    /*
    this.tabs.forEach((tab, i) => {
      if (index == i) {
        this.tabs[i] = true;
      } else {
        this.tabs[i] = false;
      }
    });
    */
  }

  unreadNewMessage(unread: boolean) {
    this.unreadMessage.emit(unread);
  }
}
