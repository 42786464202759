import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef, Input, OnChanges, SimpleChanges, HostListener, Output, EventEmitter } from '@angular/core';
import languagesJson from '@assets/languages.json';
import azureLanguagesJson from '@assets/azure-languages.json';

import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { OpentokService } from '@services/core/opentok.service';
import { LogService } from '@services/core/log.service';

import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { UtilityService } from '@services/support/utility.service';
import { TutorialDirective } from 'app/directives/tutorial.directive';

import { fromEvent, Subscription } from 'rxjs';

import { Room } from '@models/Room';
import { FlashMessageOptions } from '@models/FlashMessageOptions';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { TicketService } from '@services/other/ticket.service';
import { FormControl } from '@angular/forms';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { SoundMeter } from '@models/SoundMeter';

@Component({
  selector: 'app-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss']
})
export class ControlBarComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild("archiveDropup", { static: true }) private archiveDropup: BsDropdownDirective;

  @ViewChild("endCallTemplate", { static: true }) private endCallTemplate: TemplateRef<any>;
  @ViewChild("startRTranslationTemplate", { static: true }) private startRTranslationTemplate: TemplateRef<any>;

  @ViewChild("installExtensionTemplate", { static: true }) private installExtensionTemplate: TemplateRef<any>;

  @Input('archiving') archiving: boolean;
  @Input('archive') archive: any;
  @Input('sidebarOpen') sidebarOpen: boolean;
  @Output() onBeforeEndcall = new EventEmitter<void>();

  pAudio: boolean = true;
  private publishAudioSub: Subscription = null;
  pVideo: boolean = true;
  private publishVideoSub: Subscription = null;
  pScreen: boolean = false;
  private publishScreenSub: Subscription = null;
  pScreenDisabled: boolean = true;

  sessionTime: string = "";
  sessionTimer: any;
  timerStarted: boolean = false;

  features: { [key: string]: boolean } = {
    archiving: false,
    screensharing: false,
    reactions: false,
    snapshot: false,
    arcollaboration: false,
    arplus: false
  };
  featureMessage: "Your licence doesn't contain this feature.";

  userSub: Subscription = null;
  featuresSub: Subscription = null;
  currentRoomSub: Subscription = null;

  sessionActive: boolean = false;
  sessionActiveSub: Subscription = null;

  roomAutoArchive: boolean = false;
  userArchiveAllowed: boolean = false;
  roomPeerToPeer: boolean = false;
  archiveLoading: boolean = false;
  archiveId: string;

  rightControlsAlwaysOpen: boolean = false;

  currentRoom: Room = null;
  archivePermNeeded: boolean = false;
  archivePermRequested: boolean = false;
  archivePermGranted: boolean = false;
  archivePermissionStatus: string = null;
  archivePermissionMessageId: number = null;

  audioUnavailable: boolean = false;
  audioUnavailableSub: Subscription = null;

  videoUnavailable: boolean = false;
  videoUnavailableSub: Subscription = null;

  handRaiseInProgress: boolean = false;
  trainingDisable: boolean = false;
  roomUserStatus: { training_room: boolean, session_active: boolean, user_status: string } = null;
  private roomUserStatusSub: Subscription = null;

  someoneElsesPersonalRoom: boolean = false;
  personalRoom: boolean = true;
  expertUser: boolean = true;
  meetingRoom: boolean = true;
  showEndMeetingForAll: boolean = true;

  selectedAudioDeviceId: string = null;
  selectedAudioDeviceSub: Subscription = null;
  selectedVideoDeviceId: string = null;
  selectedVideoDeviceSub: Subscription = null;
  devicesChangedSub: Subscription = null;

  mandatoryTicket: boolean = false;
  mandatoryTicketSub: Subscription = null;

  audioDevices: any = [];
  videoDevices: any = [];

  removeMeetingLink = new FormControl(false)
  addOnsSub: Subscription = null;
  invalidateMeetingLink = false;

  azureLanguages: { languageCode: string, displayName: string }[] = [];
  languages: { languageCode: string, displayName: string }[] = [];
  selectedLanguageCode: string = null;
  selectedLanguageCode2: string = null;
  languageSub: Subscription = null;
  languageSub2: Subscription = null;

  currentPublisher: OT.Publisher;
  publisherSub: Subscription = null;

  rtranslationDisabled: boolean = false;
  rtranslationStarted: boolean = false;
  rTranslationAvailable: boolean = false;

  streamIfForTranslate: string = null;

  soundMeterSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private roomSessionService: RoomSessionService,
    private loaderService: LoaderService,
    private opentokService: OpentokService,
    private logService: LogService,
    private changeDetector: ChangeDetectorRef,
    private flashMessageService: FlashMessageService,
    private utilityService: UtilityService,
    private modalService: ModalService,
    private ticketService: TicketService,
    private multilanguageService: MultilanguageService
  ) { }

  ngOnInit() {
    this.languages = languagesJson.languages;
    this.azureLanguages = azureLanguagesJson.languages;

    this.audioUnavailableSub = this.opentokService.selectedAudioDevice.subscribe(ad => {
      this.audioUnavailable = !ad;
    });
    this.videoUnavailableSub = this.opentokService.selectedVideoDevice.subscribe(vd => {
      this.videoUnavailable = !vd;
    });

    this.publishAudioSub = this.opentokService.publishAudio.subscribe(publishAudio => {
      this.pAudio = publishAudio;
      this.changeDetector.detectChanges();
    });
    this.publishVideoSub = this.opentokService.publishVideo.subscribe(publishVideo => {
      this.pVideo = publishVideo;
      this.changeDetector.detectChanges();
    });
    this.publishScreenSub = this.opentokService.screenPublishState.subscribe(publishScreen => {
      if (publishScreen === null) {
        this.pScreenDisabled = true;
        this.changeDetector.detectChanges();
      } else {
        this.pScreenDisabled = false;

        this.pScreen = publishScreen;
        this.changeDetector.detectChanges();
      }
    });
    this.publisherSub = this.opentokService.currentPublisher.subscribe(p => {
      this.currentPublisher = p;
      this.rtranslationDisabled = !p;
    });
    this.opentokService.rtranslationPublisher.subscribe(p => {
      this.rtranslationStarted = !!p;
    })

    this.sessionActiveSub = this.roomSessionService.sessionActive.subscribe(active =>{
      this.sessionActive = active;

      if (this.sessionActive) {
        if (this.soundMeterSub) { this.soundMeterSub.unsubscribe() }
        this.soundMeterSub = null
      } else {
        this.startSoundMeter()
      }
    });

    this.languageSub = this.multilanguageService.translationLanguage.subscribe(l => {
      if (this.azureLanguages.find(lang => lang.languageCode === l)) {
        this.selectedLanguageCode = l;
      } else {
        this.selectedLanguageCode = "en";
      }
    });
    this.languageSub2 = this.multilanguageService.translationLanguage2.subscribe(l => {
      this.selectedLanguageCode2 = l;
    });

    this.featuresSub = this.authService.features.subscribe(features => {
      this.features = features;
    });

    this.addOnsSub = this.authService.addOns.subscribe(addOns => { 
      this.invalidateMeetingLink = addOns.invalidatemeetinglink ? true : false;
      this.removeMeetingLink.patchValue(this.invalidateMeetingLink);
      this.rTranslationAvailable = addOns.realtimetranslation ? true : false;
     });

    this.userSub = this.authService.user.subscribe(user => {
      this.userArchiveAllowed = user ? user.allow_archiving : false;
      this.personalRoom = user ? user.personal_room === this.callService.currentRoomId : false;
      this.expertUser = user?.role === "expert";
    });

    this.roomUserStatusSub = this.roomSessionService.roomUserStatus.subscribe(roomUserStatus => {
      this.roomUserStatus = roomUserStatus;
      this.trainingDisable = roomUserStatus.training_room ? (roomUserStatus.user_status !== "master" && roomUserStatus.user_status !== "publishing") : false;
    });

    this.currentRoomSub = this.roomSessionService.currentRoom.subscribe(room => {
      this.currentRoom = room;

      if (room) {
        this.roomAutoArchive = room.room_data.session.auto_archive;
        this.roomPeerToPeer = room.room_data.session.peer_to_peer;

        this.archivePermNeeded = room.room_data.archive_perm_needed ? true : false;
        this.archivePermRequested = room.room_data.archive_perm_requested ? true : false;
        this.archivePermGranted = room.room_data.archive_perm_granted ? true : false;
        this.meetingRoom = room.room_data.meeting_room ? true : false;
        this.showEndMeetingForAll = (room.room_data.training_room ? false : true) && this.authService.isAddOnAvailable("endmeetingforall") && !room.room_data.personal_room;

        const permissionStatus = room.room_data.requests && room.room_data.requests.archive_permission ? room.room_data.requests.archive_permission.users[this.authService.currentUser.id] : null;
        if (permissionStatus === "needed" && this.archivePermissionStatus !== "needed") {
          this.showArchiveRequestFlashMessage(room);
        }
        if (this.archivePermissionMessageId && permissionStatus !== "needed") {
          this.flashMessageService.hide(this.archivePermissionMessageId);
          this.archivePermissionMessageId = null;
        }
        this.archivePermissionStatus = permissionStatus;
      }

      if (room && room.room_data.session.start_time && !this.timerStarted) {
        this.timerStarted = true;
        this.roomSessionService.getSessionStartTime(room.room_data.session.start_time).then(time => {

          // If something went wrong, show nothing
          if ( time < 36000 && time > -3) {
            // If error small, change it to 0
            if (time < 0) { time = 0 }

            // Start timer
            this.sessionTimer = setInterval(() => {
              if (time > 3599) {
                this.sessionTime = ("0" + Math.floor(time / 3600)).slice(-2) + ":" + ("0" + Math.floor((time / 60) % 60)).slice(-2) + ":" + ("0" + time % 60).slice(-2);
              } else {
                this.sessionTime = ("0" + Math.floor(time / 60)).slice(-2) + ":" + ("0" + time % 60).slice(-2);
              }
              time += 1;
            }, 1000);
          }
        });
      }
    });

    this.selectedAudioDeviceSub = this.opentokService.selectedAudioDevice.subscribe(ad => {
      this.selectedAudioDeviceId = ad;
    });
    this.selectedVideoDeviceSub = this.opentokService.selectedVideoDevice.subscribe(vd => {
      this.selectedVideoDeviceId = vd;
    });

    this.getUserMedia();
    this.devicesChangedSub = fromEvent(navigator.mediaDevices, 'devicechange')
    .subscribe(() => this.getUserMedia());  
    
    this.mandatoryTicketSub = this.ticketService.mandatoryTicket
    .subscribe(active => {
      this.mandatoryTicket = active;
    });
  }

  async startSoundMeter() {
    try {
      const ctx = new AudioContext()
      const smeter = new SoundMeter(ctx)
      const currentStream = await OT.getUserMedia({ videoSource: null })
      if (this.sessionActive) {
        return
      }

      this.soundMeterSub = smeter.connectToSource(currentStream, true)
        .subscribe(n => n)
    } catch (error) {
      console.log(error)
    }
  }

  onToggleTranslate() {
    if (!this.sessionActive || this.pScreen) {
      // No need to show message
      return;
    }

    if (this.roomPeerToPeer) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.P2P_NO_RTRANS', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }

    if (this.rtranslationStarted) {
      this.opentokService.closeWs();
    } else {
      const modalId = this.modalService.show({
        template: this.startRTranslationTemplate,
        context: {
          dataModel: {  },
          callbacks: {
            no: () => { 
              this.modalService.hide(modalId);
            },
            yes: () => {
              this.modalService.hide(modalId);
  
              const streamId = this.currentPublisher.stream.streamId
              this.loaderService.show()
              this.opentokService.startRTranslation(streamId, this.selectedLanguageCode, this.selectedLanguageCode2)
                .finally(() => this.loaderService.hide())
            }
          }
        }
      });
    }
  }

  onLangChange(language: any) {
    this.multilanguageService.changePreferedTranslationLang(language.languageCode)
  }

  onLangChange2(language: any) {
    this.multilanguageService.changePreferedTranslationLang2(language.languageCode)
  }

  async getUserMedia() {
    const devices = await (new Promise<OT.Device[]>((resolve, reject) => {
      OT.getDevices((error, devices) => {
        if (error) {
          reject(error);
        } else {
          resolve(devices);
        }
      });
    }));

    //Implemented due to Firefox Android device list label issue
    devices.filter(d => d.kind === "audioInput").forEach((d, i) => {
      d.label = !d.label ? `Device ${i}` : d.label
    })
    devices.filter(d => d.kind === "videoInput").forEach((d, i) => {
      d.label = !d.label ? `Device ${i}` : d.label
    })
    this.audioDevices = devices.filter(d => d.kind === "audioInput");
    this.videoDevices = devices.filter(d => d.kind === "videoInput");
  }

  onAudioDeviceSelected(device) {
    this.opentokService.changeAudioDevice(device.deviceId);
  }

  onVideoDeviceSelected(device) {
    this.opentokService.changeVideoDevice(device.deviceId);
  }

  showArchiveRequestFlashMessage(room: any) {
    const flashMessageOptions: FlashMessageOptions = {
      cssClass: "alert-info", noTimeout: true, closeOnClick: false, showCloseBtn: false,
      buttons: [
        {
          title: "APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.DENY",
          cssClass: "btn-danger",
          callback: () => {
            this.opentokService.denyArchive();
            this.flashMessageService.hide(this.archivePermissionMessageId);
            this.archivePermissionMessageId = null;
          }
        },
        {
          title: "APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ALLOW",
          cssClass: "btn-success",
          callback: () => {
            this.opentokService.allowArchive();
            this.flashMessageService.hide(this.archivePermissionMessageId);
            this.archivePermissionMessageId = null;
          }
        },
      ]
    };
    const requesterName = room.room_data.requests.archive_permission.requester ? room.room_data.requests.archive_permission.requester.name : null;
    if (requesterName) {
      this.flashMessageService.showTranslatedWithData('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ARCHIVE_REQUESTED_WITH_NAME', { name: requesterName }, flashMessageOptions)
        .then(id => { this.archivePermissionMessageId = id });
    } else {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ARCHIVE_REQUESTED', flashMessageOptions)
        .then(id => { this.archivePermissionMessageId = id });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.archiving) {
      this.archiving = changes.archiving.currentValue;
    }
    if (changes.archive) {
      this.archive = changes.archive.currentValue;
    }
    if (changes.sidebarOpen) {
      this.sidebarOpen = changes.sidebarOpen.currentValue;
      this.rightControlsAlwaysOpen = false;
    }
  }

  ngOnDestroy() {
    if (this.sessionTimer) { clearInterval(this.sessionTimer) }
    if (this.sessionActiveSub) { this.sessionActiveSub.unsubscribe() }
    if (this.audioUnavailableSub) { this.audioUnavailableSub.unsubscribe() }
    if (this.videoUnavailableSub) { this.videoUnavailableSub.unsubscribe() }
    if (this.publishAudioSub) { this.publishAudioSub.unsubscribe() }
    if (this.publishVideoSub) { this.publishVideoSub.unsubscribe() }
    if (this.publishScreenSub) { this.publishScreenSub.unsubscribe() }
    if (this.currentRoomSub) { this.currentRoomSub.unsubscribe() }
    if (this.featuresSub) { this.featuresSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.roomUserStatusSub) { this.roomUserStatusSub.unsubscribe() }
    if (this.selectedAudioDeviceSub) { this.selectedAudioDeviceSub.unsubscribe() }
    if (this.selectedVideoDeviceSub) { this.selectedVideoDeviceSub.unsubscribe() }
    if (this.devicesChangedSub) { this.devicesChangedSub.unsubscribe() }
    if (this.mandatoryTicketSub) { this.mandatoryTicketSub.unsubscribe() }
    if (this.addOnsSub) { this.addOnsSub.unsubscribe() }
    if (this.languageSub) { this.languageSub.unsubscribe() }
    if (this.languageSub2) { this.languageSub2.unsubscribe() }
    if (this.publisherSub) { this.publisherSub.unsubscribe() }
    if (this.soundMeterSub) { this.soundMeterSub.unsubscribe() }

    this.flashMessageService.hide(this.archivePermissionMessageId);
    this.archivePermissionMessageId = null;
  }

  onEndCall(endForAll: boolean) {
    this.ticketService.mandatoryTicketSource.next(false)
    const modalId = this.modalService.show({
      template: this.endCallTemplate,
      context: {
        dataModel: { personalRoom: this.personalRoom, showEndMeetingForAll: this.showEndMeetingForAll, meetingRoom: this.meetingRoom, endForAll: endForAll },
        callbacks: {
          no: () =>{ 
            this.modalService.hide(modalId)
            this.ticketService.mandatoryTicketSource.next(true)
          },
          yes: () => {
            this.onBeforeEndcall.emit();

            this.modalService.hide(modalId);
            this.loaderService.show();

            setTimeout(() => {
              this.removeMeetingLink.patchValue(!endForAll || !this.meetingRoom ? false : this.removeMeetingLink.value)
              const removeMeetingLinkParam = this.removeMeetingLink.value
              this.callService.endCall(endForAll, removeMeetingLinkParam)
                .catch(error => {
                  console.log(error);
                  this.callService.simulateEndcallForFrontend(this.roomSessionService.currentRoomId, this.roomSessionService.currentSessionId, "endcall");
                })
                .finally(() => this.loaderService.hide());
            }, 100);
          }
        }
      }
    });
  }

  onToggleAudio() {
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
    if (this.audioUnavailable) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.CAM_MIC_CANT_ACCESS');
      return;
    }
    this.opentokService.toggleAudio();
  }

  onToggleVideo() {
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
    if (this.videoUnavailable) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.CAM_MIC_CANT_ACCESS');
      return;
    }
    if (!this.pScreen) {
      this.opentokService.toggleVideo();
    }
  }

  onStartArchive(event) {
    if (this.roomAutoArchive || this.archiveLoading) {
      // No need to show message
      return;
    }
    if (!this.features.archiving) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE', { timeout: 6000 });
      return;
    }
    if (!this.userArchiveAllowed) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ARCHIVE_NO_PERMISSION', { timeout: 6000 });
      return;
    }
    if (this.roomPeerToPeer) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.P2P_NO_ARCHIVE', { timeout: 6000 });
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }

    if (this.archivePermNeeded) {
      if (!this.archivePermGranted) {
        if (this.archivePermRequested) {
          this.archiveLoading = true;
          this.opentokService.cancelArchiveRequest().finally(() => {
            this.archiveLoading = false;
          });
        } else {
          event.stopPropagation();
          this.archiveDropup.toggle(true);
        }
      }
    } else {
      this.archiveLoading = true;
      this.opentokService.startArchive().finally(() => {
        this.archiveLoading = false;
      });
    }
    /*if (this.currentRoom && this.currentRoom.room_data.archive_perm_needed) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ARCHIVE_PERMISSION_REQUESTED', { cssClass: "alert-info" });
    }*/
  }

  onAskRecordPermission() {
    if (this.roomAutoArchive) {
      // No need to show message
      return;
    }
    if (!this.features.archiving) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE', { timeout: 6000 });
      return;
    }
    if (!this.userArchiveAllowed) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ARCHIVE_NO_PERMISSION', { timeout: 6000 });
      return;
    }
    if (this.roomPeerToPeer) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.P2P_NO_ARCHIVE', { timeout: 6000 });
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }

    this.archiveLoading = true;
    this.opentokService.startArchive().finally(() => {
      this.archiveLoading = false;
    });
  }

  onStopArchive() {
    if (!this.userArchiveAllowed) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ARCHIVE_NO_PERMISSION', { timeout: 6000 });
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }

    this.archiveLoading = true;
    this.opentokService.stopArchive(this.archive.id).finally(() => {
      this.archiveLoading = false;
    });
  }

  onToggleScreenSharing() {
    if (this.pScreen) {
      this.opentokService.endScreenSharing();
    } else if (this.rtranslationStarted) {
      this.flashMessageService.show('Screen sharing not supported while realtime translation enabled.');
    } else if (!this.features.screensharing) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    } else if (this.trainingDisable) {
      if (this.sessionActive) {
        this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      }
    } else if (!this.pScreenDisabled) {
      this.opentokService.checkScreenSharingCapability()
      .then(response => {
        const _response: any = response;

        if (!response.supported || _response.extensionRegistered === false) {
          const browserName = this.utilityService.getBrowserParser().getBrowserName();
          const browserVersion = parseInt(this.utilityService.getBrowserParser().getBrowserVersion().split('.')[0]);
          if ((browserName === "Firefox" && browserVersion < 52) || (browserName === "Chrome" && browserVersion < 72)) {
            this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.SCREEN_SHARE_UPDATE');
          } else {
            this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.SCREEN_SHARE_NOT_SUPPORTED');
          }
        } else if (this.utilityService.getBrowserParser().is("Safari")) {
          this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.SCREEN_SHARE_USE_OTHER');
        } else {
          this.startScreenSharing();
        }
      });
    }
  }

  startScreenSharing() {
    this.opentokService.startScreenSharing()
    .catch(error => {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.SCREEN_SHARE_FAILED');
    })
  }

  onReactionClick() {
    if (!this.features.reactions) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }
    if (!this.sessionActive) {
      // No need to show message
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
  }

  onSendReaction(reaction: string) {
    this.logService.sendReactionLog(reaction);
  }

  onReactionNext(tutorial: TutorialDirective) {
    if (this.features.arcollaboration) {
      if (this.features.arplus) {
        tutorial.next({subscriber: true, arColl: true, arPlus: true, noArPlus: true});
      } else {
        tutorial.next({subscriber: true, arColl: true, noArPlus: true});
      }
    } else {
      if (this.features.arplus) {
        tutorial.next({subscriber: true, arPlus: true, noArPlus: true});
      } else {
        if (this.features.snapshot) {
          tutorial.next({subscriber: true, snapshot: true, noSnapshot: true});
        } else {
          tutorial.next({showTutorial: true});
        }
      }
    }
  }

  onRaiseHand() {
    this.handRaiseInProgress = true;
    this.roomSessionService.raiseHand()
    .finally(() => { this.handRaiseInProgress = false; })
  }

  onLowerHand() {
    this.handRaiseInProgress = true;
    this.roomSessionService.lowerHand()
    .finally(() => { this.handRaiseInProgress = false; })
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick() {
    if (this.archiveDropup.isOpen) {
      this.archiveDropup.hide();
    }
  }
}
