import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, OnDestroy, SimpleChanges, Input, Renderer2, TemplateRef, HostListener } from '@angular/core';

import { OpentokService } from '@services/core/opentok.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { ImageCollaborationService } from '@services/other/image-collaboration.service';
import { PdfCollaborationService } from '@services/other/pdf-collaboration.service';
import { LogService } from '@services/core/log.service';
import { TutorialService } from '@services/support/tutorial.service';
import { AuthService } from '@services/core/auth.service';
import { FullscreenService } from '@services/support/fullscreen.service';
import { UtilityService } from '@services/support/utility.service';

import { ResizedEvent } from 'angular-resize-event';
import { Frame } from '@models/Frame';

import { Subscription, fromEvent } from 'rxjs';
import { auditTime, distinctUntilChanged, map, filter, findIndex } from 'rxjs/operators';
import { ModalService } from '@services/support/modal.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { ArCollaborationService } from '@services/core/ar-collaboration.service';

import * as bowser from 'bowser';

@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.scss']
})
export class VideoChatComponent implements OnInit, OnDestroy {

  @Input('sidebarOpen') sidebarOpen: boolean;
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild("tryToPublishTemplate", { static: true }) private tryToPublishTemplate: TemplateRef<any>;
  @ViewChild("tryToFocusTemplate", { static: true }) private tryToFocusTemplate: TemplateRef<any>;

  isTouchDevice: boolean = false;

  roomSessionStarted: boolean = false;
  roomSessionStartedSub: Subscription = null;
  opentokSession: OT.Session;
  isOpentokStarted: boolean = false;

  final_id: number = 0;
  container_height: number;
  container_width: number;
  frames: Frame [] = [];

  collaborationSub: Subscription = null;
  padding_vertical: number = 5;
  padding_horizontal: number = 5;
  resizeTimeout: any;
  deafult_margin: number;

  resizeSub: Subscription = null;

  showReaction: boolean = false;
  reactionTimeout: any = null;
  reactionSub: Subscription = null;
  reactionSource: string;
  reactionType: string;

  archiving: boolean = false;
  archive: any = null;

  archiveIndicator: boolean = false;
  archiveInterval: any = null;

  waitExpertSub: Subscription = null;
  waitExpert: boolean = false;

  fullscreenState: {fullscreen: boolean, supported: boolean} = {fullscreen: false, supported: true};
  fullscreenSub: Subscription = null;
  isSafari: boolean = false;

  aspect_ratio: number = 4/3;

  roomUserStatus = { training_room: false, session_active: false, user_status: "" };
  private roomUserStatusSub: Subscription = null;
  private tryToPublishModalId: number = null;

  publishStatus = {
    audio: false,
    video: false
  };
  publishAudioSub: Subscription = null;
  publishVideoSub: Subscription = null;

  focusData: any = null;
  sessionDataSub: Subscription = null;

  audioUnavailable: boolean = false;
  audioUnavailableSub: Subscription = null;

  videoUnavailable: boolean = false;
  videoUnavailableSub: Subscription = null;

  isExpert: boolean = false;
  currentUser

  browser
  isMobileScreen
  showUsers = true
  frameCounter: number = 1

  isPortrait
  mobileFullscreen

  constructor(
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private opentokService: OpentokService,
    private collaborationService: CollaborationService,
    private arCollaborationService: ArCollaborationService,
    private utilityService: UtilityService,
    private changeDetector: ChangeDetectorRef,
    private logService: LogService,
    private fullscreenService: FullscreenService,
    private modalService: ModalService,
    private flashMessageService: FlashMessageService,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    private imageCollaborationService: ImageCollaborationService,
    private pdfCollaborationService: PdfCollaborationService,
    private tutorialService: TutorialService
  ) {
    this.isTouchDevice = 'ontouchstart' in window;

    const parser = this.utilityService.getBrowserParser();
    this.isSafari = parser.isBrowser("Safari");

    this.authService.user.subscribe(user => {
      this.isExpert = user?.role === "expert";
    });

    this.roomUserStatusSub = this.roomSessionService.roomUserStatus
    .pipe(distinctUntilChanged((prev, cur) => prev.user_status === cur.user_status)) // Only accept status changes
    .subscribe(roomUserStatus => {
      this.roomUserStatus = roomUserStatus;

      if (roomUserStatus.user_status === "try-to-publish") {
        if (this.isOpentokStarted && roomUserStatus.session_active) {
          this.tryToPublish();
        }
      } else if (roomUserStatus.user_status === "waiting") {
        if (this.tryToPublishModalId) {
          this.modalService.hide(this.tryToPublishModalId);
          this.tryToPublishModalId = null;
          this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.HOST_WITHDRAW_REQUEST', { timeout: 6000 });
        }
        const i = this.frames.findIndex(frame => frame.type === 'publisher');
        if (i > -1) {
          this.removeFrame(this.frames[i], i);
          this.updateScreen();
        }
      } else if (roomUserStatus.user_status === "publishing" || this.roomUserStatus.user_status === "master") {
        if (this.isOpentokStarted) {
          this.addFrame({ type: "publisher", uid: this.authService.currentUser.id, isCollaboration: false, fullscreen: false, pinned: false });
          this.updateScreen();
        }
      }
    });

    this.publishAudioSub = this.opentokService.publishAudio.subscribe(publishAudio => {
      this.publishStatus.audio = publishAudio;
    });
    this.publishVideoSub = this.opentokService.publishVideo.subscribe(publishVideo => {
      this.publishStatus.video = publishVideo;
    });

    this.sessionDataSub = roomSessionService.sessionData.pipe(
      map(d => d && d.focus ? d.focus : null),
      distinctUntilChanged((x,y) => (x === y) || (x && y && x.id === y.id && x.type === y.type))
    ).subscribe(focus => {
      this.focusData = focus;
      if (focus) {
        if (focus.type === "collaboration") {
          let pinned = null;
          for (const f of this.frames) {
            if (f.isCollaboration && !pinned) {
              pinned = f;
              f.pinned = true;
            } else {
              f.pinned = false;
            }
          }
          if (pinned) {
            this.makePinnedFrameBig(pinned);
          } else {
            this.updateScreen();
          }
        } else {
          let pinned = null;
          for (const f of this.frames) {
            if (f.uid === focus.id && !pinned) {
              pinned = f;
              f.pinned = true;
              this.makePinnedFrameBig(f);
            } else {
              f.pinned = false;
            }
          }
          if (pinned) {
            this.makePinnedFrameBig(pinned);
          } else {
            this.updateScreen();
          }
        }
      } else {
        this.frames.forEach(f => { f.pinned = false; });
        this.updateScreen();
      }
    });
  }

 SpeakerDetection = function(subscriber, startTalking, stopTalking) {
  let activity = null;
  subscriber.on('audioLevelUpdated', function(event) {
    const now = Date.now();
    if (event.audioLevel > 0.2) {
      if (!activity) {
        activity = {timestamp: now, talking: false};
      } else if (activity.talking) {
        activity.timestamp = now;
      } else if (now- activity.timestamp > 1000) {
        // detected audio activity for more than 1s
        // for the first time.
        activity.talking = true;
        if (typeof(startTalking) === 'function') {
          startTalking();
        }
      }
    } else if (activity && now - activity.timestamp > 3000) {
      // detected low audio activity for more than 3s
      if (activity.talking) {
        if (typeof(stopTalking) === 'function') {
          stopTalking();
        }
      }
      activity = null;
    }
  });
};

  pinFrame(frame: Frame) {
    if (this.isExpert || (this.roomUserStatus?.user_status === "master")) {
      if (frame.pinned) {
        this.roomSessionService.disableFrameFocus();
      } else {
        this.tryToEnableFocus(frame);
      }
    }
  }

  tryToEnableFocus(frame: Frame) {
    this.roomSessionService.enableFrameFocus(frame)
    .then(result => {
      if (!result.committed) {
        const modalId = this.modalService.show({
          template: this.tryToFocusTemplate,
          context: {
            dataModel: null,
            callbacks: {
              no: () => this.modalService.hide(modalId),
              yes: () => {
                this.arCollaborationService.closeAllArPluses()
                .then(() => {
                  this.tryToEnableFocus(frame);
                  this.modalService.hide(modalId);
                })
              }
            }
          }
        });
      }
    })
  }

  tryToPublish() {
    if (!this.publishStatus.audio) { this.opentokService.toggleAudio(); }
    if (!this.publishStatus.video) { this.opentokService.toggleVideo(); }

    this.tryToPublishModalId = this.modalService.show({
      template: this.tryToPublishTemplate,
      context: {
        dataModel: this.publishStatus,
        callbacks: {
          toggleAudio: () => this.opentokService.toggleAudio(),
          toggleVideo: () => this.opentokService.toggleVideo(),
          no: () => {
            this.modalService.hide(this.tryToPublishModalId);
            this.tryToPublishModalId = null;
            this.roomSessionService.denyPublish();
          },
          yes: () => {
            this.modalService.hide(this.tryToPublishModalId);
            this.tryToPublishModalId = null;
            this.roomSessionService.acceptPublish();
          }
        }
      }
    });
  }

  onVideoChatContainerResized(event: ResizedEvent) {
    this.deafult_margin = this.container_width*0.05;
    this.updateScreen();
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    } 
  }

  onFrameResized(frame: Frame, event: ResizedEvent) {

    // trigger resize of corresponding collaboration component if the frame is a collaboration
    if(frame.isCollaboration) {
      if(frame.type === "image" || frame.type=="screenshot")
        this.imageCollaborationService.triggerResize();
      else if(frame.type === "pdf")
        this.pdfCollaborationService.triggerResize();
    }
    let top = 0;
    let left = 0;
    let width = 0;
    let height = 0;
    if (event.newWidth*0.75 > event.newHeight) {
      height = event.newHeight;
      width = event.newHeight*4.0/3.0;
      left = (event.newWidth - width) / 2.0;
    } else {
      width = event.newWidth;
      height = event.newWidth*0.75;
      top = (event.newHeight - height) / 2.0;
    }

    frame.style2 = {
      "margin-top"  : top     +"px",
      width         : width   +"px",
      height        : height  +"px",
      "margin-left" : left    +"px"
    }
  }

  ngOnInit() {
    this.opentokService.on("sessionStarted", this.opentokSessionStarted);
    this.opentokService.on("sessionEnded", this.opentokSessionEnded);
    this.opentokService.on("streamCreated", this.opentokStreamCreated);
    this.opentokService.on("streamDestroyed", this.opentokStreamDestroyed);
    this.opentokService.on("sessionArchiving", this.opentokArchiveStatusChanged);

    this.currentUser = this.authService.currentUser

    this.roomSessionStartedSub = this.roomSessionService.sessionActive
    .subscribe(active => {
      this.roomSessionStarted = active;

      if (active) {
        if (!this.tutorialService.isTutorialCompleted("main")) {
          this.tutorialService.startTutorial({micCamOff: true}, 'initialTutorial')
          .then(skipped => {
            if (!skipped) {
              this.tutorialService.saveCompletedTutorial("main");
            }
          });
        }
      } else {
        if (this.tutorialService.currentController && !this.tutorialService.currentController.isEnded()) {
          this.tutorialService.currentController.skip();
        }

        this.frames.filter(frame => frame.type === 'subscriber').forEach(frame => {
          this.removeFrame(frame, this.frames.indexOf(frame));
        });
        this.updateScreen();

        if (this.fullscreenState.fullscreen) {
          this.fullscreenService.exitFullscreen();
        }
      }
      this.changeDetector.detectChanges();
    });

    this.collaborationSub = this.collaborationService.collaboration.subscribe(collData => {
      if (collData) {
        this.addFrame({ type: collData.type, isCollaboration: true, collaborationData: collData, fullscreen: false, pinned: false });
        this.updateScreen();
      } else {
        
        this.frames.filter(frame => frame.isCollaboration).forEach(frame => {
          this.removeFrame(frame, this.frames.indexOf(frame));
        });
        this.updateScreen();
      }
    });
    this.container_height = this.container.nativeElement.clientHeight;
    this.container_width = this.container.nativeElement.clientWidth;
    this.deafult_margin = this.container_width*0.05;

    
    this.resizeSub = fromEvent(window, 'resize')
    .pipe(
      auditTime(500)
    )
    .subscribe((event) => {
      this.imageCollaborationService.triggerResize();
      this.pdfCollaborationService.triggerResize();
      this.deafult_margin = this.container_width*0.05;
      this.updateScreen();
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      } 
    });

    this.reactionSub = this.logService.onReactionRequest.subscribe(message => {
      this.showReactionMessage(message.owner_info, message.content);
    });

    this.waitExpertSub = this.authService.license.subscribe(license => {
      this.waitExpert = (license.type === 'concurrent_user_based' ? false : (this.authService.currentUser.role === 'expert' ? false : true));
    });

    this.fullscreenSub = this.fullscreenService.isFullscreen.subscribe(state => {
      this.fullscreenState = state;

      if (!state.fullscreen) {
        for (const frame of this.frames) {
          frame.fullscreen = false;
        }
        this.updateScreen();
        // Wait 0.5s, it equals transition time
        setTimeout(() => {
          for (const frame of this.frames) {
            this.renderer.setStyle(frame.nativeElement.parentElement, "transition", "all 0.5s ease-out");
            this.renderer.setStyle(frame.nativeElement.parentElement, "-webkit-transition", "all 0.5s ease-out");
          }
        }, 500);
      }
    });

    this.audioUnavailableSub = this.opentokService.selectedAudioDevice.subscribe(ad => {
      this.audioUnavailable = !ad;
    });
    this.videoUnavailableSub = this.opentokService.selectedVideoDevice.subscribe(vd => {
      this.videoUnavailable = !vd;
    });

    this.browser = bowser.getParser(window.navigator.userAgent);

    this.isMobileScreen = this.browser.is('mobile');

    this.opentokService.rtranslationPublisher.subscribe(p => {
      if (p) {
        this.addFrame({ type: "publisher2", uid: this.authService.currentUser.id, isCollaboration: false, fullscreen: false, pinned: false });
        this.updateScreen();
      } else {
        const i = this.frames.findIndex(frame => frame.type === 'publisher2');
        if (i > -1) {
          this.removeFrame(this.frames[i], i);
          this.updateScreen();
        }
      }
    })
  }

  onBeforeEndcall() {
    if (this.isMobileScreen) {
      this.frames.filter(frame => frame.type === 'subscriber').forEach(frame => {
        this.removeFrame(frame, this.frames.indexOf(frame));
      });
      this.updateScreen();
    }
  }

  startTrainingSession() {
    this.loaderService.show();
    this.roomSessionService.startTrainingSession()
    .finally(() => this.loaderService.hide());
  }
  
  ngOnChanges(changes: SimpleChanges){
    let interval = null;
    if(changes.sidebarOpen.previousValue != changes.sidebarOpen.currentValue){
      this.sidebarOpen = changes.sidebarOpen.currentValue;
    
      if (interval) { clearInterval(interval) }
      setInterval(() => {
        if (this.container_width != this.container.nativeElement.clientWidth){
          clearInterval(interval);

          setTimeout(() =>{
            this.imageCollaborationService.triggerResize();
            this.pdfCollaborationService.triggerResize();
          },400);
          
          this.updateScreen();
          if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
          }
        } 
      }, 100);

      if (this.sidebarOpen && this.fullscreenState.fullscreen) {
        this.fullscreenService.exitFullscreen();
      }
    }
  }

  private opentokSessionStarted = (session: OT.Session) => {
    this.opentokSession = session;
    this.isOpentokStarted = true;

    if (this.roomUserStatus && this.roomUserStatus.training_room) {
      if (this.roomUserStatus.user_status === "try-to-publish") {
        this.tryToPublish();
      } else if (this.roomUserStatus.user_status === "master" || this.roomUserStatus.user_status === "publishing") {
        this.addFrame({ type: "publisher", uid: this.authService.currentUser.id, isCollaboration: false, fullscreen: false, pinned: false });
        this.updateScreen();
      }
    } else {
      this.addFrame({ type: "publisher", uid: this.authService.currentUser.id, isCollaboration: false, fullscreen: false, pinned: false });
      this.updateScreen();
    }
  }

  private opentokSessionEnded = () => {
    this.opentokSession = null;
    const i = this.frames.findIndex(frame => frame.type === 'publisher');
    if (i > -1) {
      this.removeFrame(this.frames[i], i);
      this.updateScreen();
    }
    
    this.isOpentokStarted = false;
    this.changeDetector.detectChanges();
  }

  private opentokStreamCreated = (stream: OT.Stream) => {
    const subData = JSON.parse(stream.connection.data);
    const uid = subData.uid;
    const isMobile = subData.mobile;
    const videoType = stream.videoType;

    /*
    // Dublicate subscriber check
    const i = this.frames.findIndex(f => f.type === "subscriber" && f.uid === uid);
    if (i > -1) {
      this.removeFrame(this.frames[i], i);
    }*/
    this.addFrame({ type: "subscriber", isCollaboration: false, stream: stream, uid: uid, videoType: videoType, isMobile: isMobile, fullscreen: false, pinned: false, isArPlusOpen: false });
    this.updateScreen();
  }

  private opentokStreamDestroyed = (stream) => {
    const i = this.frames.findIndex(frame => frame.stream === stream);
    if (i > -1) {
      this.removeFrame(this.frames[i], i);
      this.updateScreen();
    }
  }

  private opentokArchiveStatusChanged = (archiving: boolean, archive: any) => {
    this.archiving = archiving;
    this.archive = archive;

    if (archiving) {
      if (this.archiveInterval) {
        clearInterval(this.archiveInterval);
      }
      this.archiveInterval = setInterval(() => {
        this.archiveIndicator = !this.archiveIndicator;
      }, 1000);
    } else {
      if (this.archiveInterval) {
        clearInterval(this.archiveInterval);
      }
      this.archiveIndicator = false;
    }
  }

  showReactionMessage(source: string, reaction: string) {
    this.reactionSource = source;
    this.reactionType = reaction;

    if (this.reactionTimeout) {
      clearTimeout(this.reactionTimeout);
    }
    this.showReaction = true;
    this.reactionTimeout = setTimeout(() => {
      this.showReaction = false;
      this.reactionTimeout = null;
    }, 2000);
  }

  ngOnDestroy() {
    this.opentokService.off("sessionStarted", this.opentokSessionStarted);
    this.opentokService.off("sessionEnded", this.opentokSessionEnded);
    this.opentokService.off("streamCreated", this.opentokStreamCreated);
    this.opentokService.off("streamDestroyed", this.opentokStreamDestroyed);
    this.opentokService.off("sessionArchiving", this.opentokArchiveStatusChanged);

    if (this.roomSessionStartedSub) { this.roomSessionStartedSub.unsubscribe() }
    if (this.fullscreenSub) { this.fullscreenSub.unsubscribe() }
    if (this.collaborationSub) { this.collaborationSub.unsubscribe() }
    if (this.resizeSub) { this.resizeSub.unsubscribe() }
    if (this.reactionSub) { this.reactionSub.unsubscribe() }
    if (this.waitExpertSub) { this.waitExpertSub.unsubscribe() }
    if (this.roomUserStatusSub) { this.roomUserStatusSub.unsubscribe() }
    if (this.publishAudioSub) { this.publishAudioSub.unsubscribe() }
    if (this.publishVideoSub) { this.publishVideoSub.unsubscribe() }
    if (this.sessionDataSub) { this.sessionDataSub.unsubscribe() }
    if (this.videoUnavailableSub) { this.videoUnavailableSub.unsubscribe() }
    if (this.audioUnavailableSub) { this.audioUnavailableSub.unsubscribe() }

    if (this.archiveInterval) {
      clearInterval(this.archiveInterval);
    }
  }

  makePinnedFrameBig(frame: Frame) {
    if (frame.size === "small") {
      const i = this.frames.findIndex(f => frame === f);
      if (i > -1) {
        let temp_id = this.frames[i].id;
        let j;
        for(let k=0; k<this.frames.length; k++){
          if(this.frames[k].id==this.final_id-1){
            j=k;
            break;
          }
        }
        let big_id = this.frames[j].id;
        this.frames[i].id = big_id;
        this.frames[j].id = temp_id;

        this.updateScreen();
      }
    }

    if (this.fullscreenState.fullscreen && !frame.fullscreen) {
      this.fullscreenService.exitFullscreen();
    }
  }

  // --common--
  // type
  // isCollaboration
  // fullscreen
  // pinned

  // --collaboration--
  // collaborationData

  // --publisher--
  // uid

  // --subscriber--
  // uid
  // stream
  // videotype
  // isMobile
  // isArPlusOpen

  onSubscriberArPlusStatusChanged(isArPlusOpen: boolean, index: number) {
    if (isArPlusOpen) {
      this.onMaximize(index);
    } else {
      this.onMaximize(this.findNextBigScreenIndex());
    }
  }

  findNextBigScreenIndex() : number {
    let nextFrame = this.frames[0];
    let nextIndex = 0;
    for (let i = 1; i < this.frames.length; i++) {
      if (this.frames[i].isArPlusOpen || this.frames[i].isCollaboration) {
        nextFrame = this.frames[i];
        nextIndex = i;
        break;
      } else if (!nextFrame.isArPlusOpen && this.frames[i].videoType === "screen") {
        nextFrame = this.frames[i];
        nextIndex = i;
      } else if (!nextFrame.isArPlusOpen && nextFrame.videoType !== "screen" && this.frames[i].isMobile) {
        nextFrame = this.frames[i];
        nextIndex = i;
      }
    }
    return nextIndex;
  }

  addFrame(frame: Frame) {
    // If the added new window is already pinned, make changes
    if (this.focusData) {
      if (frame.isCollaboration) {
        if (this.focusData.type === "collaboration") {
          frame.pinned = true;
          this.frames.forEach(f => { f.pinned = false; });
          this.makePinnedFrameBig(frame);
        }
      } else if (this.focusData.id === frame.uid) {
        frame.pinned = true;
        this.frames.forEach(f => { f.pinned = false; });
        this.makePinnedFrameBig(frame);
      }
    }
    //const platform =  JSON.parse(stream.connection.data).platform;
    const final_frame = this.frames.filter(frame => frame.id === this.final_id-1)[0];

    //check whether there is any frame in the room
    if(final_frame){
      if(final_frame.pinned){
        //if the big one is collaboration then swap the ids so that collaboration has still final id.
        final_frame.id = this.final_id;
        frame.id = this.final_id - 1;
        this.frames.push(frame);
      }
      else if(final_frame.isCollaboration || final_frame.isArPlusOpen){
        //if the big one is collaboration or mobile with arplus then swap the ids so that collaboration has still final id.
        final_frame.id = this.final_id;
        frame.id = this.final_id - 1;
        this.frames.push(frame);
      }
        //if the big one is screen share 
      else if(final_frame.videoType === "screen"){
        if(frame.isCollaboration){
          frame.id = this.final_id;
          this.frames.push(frame);
        }
        // if not collab then swap ids
        else {
          final_frame.id = this.final_id;
          frame.id = this.final_id - 1;
          this.frames.push(frame);
        }
      }
      //if not collaboration or ar plus or screen share, check whether it is a mobile or not!
      else if(final_frame.isMobile){
        //if the big one is mobile then check whether the new frame is collaboration or screen share
        if(frame.isCollaboration || frame.videoType === "screen"){
          //if it is collaboration then push it to frames so that collaboration can stay as big
          frame.id = this.final_id;
          this.frames.push(frame);
        }
        //if not collaboration then swap the ids so that the mobile can stay as big
        else{
          final_frame.id = this.final_id;
          frame.id = this.final_id - 1;
          this.frames.push(frame);
        }
      }
      //so it is a web camera. Then push the frames with new id.
      else{
        frame.id = this.final_id;
        this.frames.push(frame);
      }
      this.final_id++;
      return;
    }

    //if no frame exist, push the new one.
    frame.id = this.final_id++;
    this.frames.push(frame);
  }

  removeFrame(frame: Frame, index: number) {
    this.final_id--;
    let id = frame.id;
    for(let i = 0; i<this.frames.length; i++){
      if(this.frames[i].id > id)
        this.frames[i].id--;
    }
    this.frames.splice(index, 1);
    if (this.frames.length > 0){
      this.onMaximize(this.findNextBigScreenIndex());
    }
  }

  onMaximize(i: number){
    if (this.focusData) {
      return;
    }
    let temp_id = this.frames[i].id;
    let j;
    for(let k=0; k<this.frames.length; k++){
      if(this.frames[k].id==this.final_id-1){
        j=k;
        break;
      }
    }
    
    let big_id = this.frames[j].id;
    this.frames[i].id = big_id;
    this.frames[j].id = temp_id;

    this.updateScreen();

    if(document.getSelection() && document.getSelection().empty) {
      document.getSelection().empty();
    } else if(window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
    }
    this.changeDetector.detectChanges();
  }

  /*****************************************************************************************************************************************/
  /********************************************************* FULL-SCREEN********************************************************************/
  /*****************************************************************************************************************************************/
  /**
  * @param {boolean} makeFullscreen when fullscreen button is clicked, it sets to true, otherwise false
  */
  toggleFullscreen(makeFullscreen: boolean, frame: Frame) {
    if (makeFullscreen) {
      this.mobileFullscreen = true
      if (this.isSafari) {
        this.renderer.setStyle(frame.nativeElement.parentElement, "left", "0");
        this.renderer.setStyle(frame.nativeElement.parentElement, "top", "0");
        this.renderer.setStyle(frame.nativeElement.parentElement, "width", "100%");
        this.renderer.setStyle(frame.nativeElement.parentElement, "height", "100%");
        this.renderer.setStyle(frame.nativeElement.parentElement, "transition", "none");
        this.renderer.setStyle(frame.nativeElement.parentElement, "-webkit-transition", "none");
        frame.style = {
          left: "0",
          top: "0",
          width: "100%",
          height: "100%"
        }
      }

      frame.fullscreen = true;

      frame.style = {
        left: "0",
        top: "0",
        width: "100%",
        height: "100%"
      }
      
      const supported = this.fullscreenService.requestFullscreen(frame.nativeElement.parentElement);
      //If not supported, then right big screen stays the same.
      if (!supported) {
        frame.style = {
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          "z-index": "10",
          "background-color": "white"
        }
      }
    } else {
      frame.fullscreen = false;
      this.mobileFullscreen = false
      this.updateScreen();
      this.fullscreenService.exitFullscreen();
    }
  }

  toggleDownloadButtonClicked(isDownloadButtonClicked: boolean, frame: Frame) {
    if (isDownloadButtonClicked) {
      if (frame.fullscreen) {     
        //This if condition added due to bug found when fullscreen button clicked during pdf collaboration.   
        frame.style = {
          "margin-top"  :  "-60px", //Feel free to change if any problem occurs.
          "margin-left" :  "0px"
        }

        setTimeout(() => {
          if (this.isSafari) {
            this.renderer.setStyle(frame.nativeElement.parentElement, "left", "0");
            this.renderer.setStyle(frame.nativeElement.parentElement, "top", "0");
            this.renderer.setStyle(frame.nativeElement.parentElement, "width", "100%");
            this.renderer.setStyle(frame.nativeElement.parentElement, "height", "100%");
            this.renderer.setStyle(frame.nativeElement.parentElement, "transition", "none");
            this.renderer.setStyle(frame.nativeElement.parentElement, "-webkit-transition", "none");
            
            frame.style = {
              left: "0",
              top: "0",
              width: "100%",
              height: "100%"
            }
          }
  
          const supported = this.fullscreenService.requestFullscreen(frame.nativeElement.parentElement);
          //If not supported, then right big screen stays the same.
          if (!supported) {
            frame.style = {
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              "z-index": "10",
              "background-color": "white"
            }
          }
        }, 100);        
      }
    }
  }

  updateScreen(){
    if(this.isMobileScreen) {
      let firstStream = this.frames[0]
      this.frames.forEach((frame,index) => {
        if(frame.type == 'image' || frame.type == 'pdf' || frame.type == 'screenshot') {
          this.frames[0] = this.frames[index]
          this.frames[index] = firstStream
        }
      })
      if(this.frames.length) {
       if( !(this.frames[0].type == 'image' || this.frames[0].type == 'pdf' || this.frames[0].type == 'screenshot') ) {
        let publisherIndex = this.frames.findIndex(frame => frame.type == 'publisher')
        let publisher = this.frames[publisherIndex]
        this.frames.splice(publisherIndex, 1)
        this.frames.unshift(publisher)
       }
       let isFramePinned = this.frames.find(frame => frame.pinned)
       if (isFramePinned) {
        let pinnedFrameIndex = this.frames.findIndex(frame => frame.pinned)
        let pinnedFrame = this.frames[pinnedFrameIndex]
        this.frames.splice(pinnedFrameIndex, 1)
        this.frames.unshift(pinnedFrame)
       }
      }
    }
    // take general container width height
    this.container_width = this.container.nativeElement.clientWidth;
    this.container_height = this.container.nativeElement.clientHeight;
    
    let isPortrait = false;
    
    let length = this.frames.length; // number of frames

    let vertical_ratio;    // ==>  container_height / vertical_ratio = small_height
    
    // set vertical ratio accordingly for the first couple of case
    if (length <= 4) {
      vertical_ratio = 3;
    } 
    else if (length == 5) {
      vertical_ratio = 4;
    } 
    else if(length%2==1) {
      vertical_ratio = length-1;
    }
    else
      vertical_ratio = length;

    let small_height = 0;
    let small_width = 0;
    let big_height = 0;
    let big_width = 0;
    let left_top_margin = 0;
    let right_top_margin = 0;
    let ls_left_margin = 0;
    let rs_left_margin = 0;

    /*
    * 
    * PORTRAIT
    *  ______
    * |      |
    * |      |
    * |      |
    * |      |
    * |______|
    * 
    */
    if(this.container_width<this.container_height){
      this.isPortrait = true

      isPortrait = true;
      small_height = (this.container_height-(length+1)*this.padding_vertical)/length;
      small_width = small_height*this.aspect_ratio;
      
      // width overflow
      if(small_width>this.container_width) {

        // in this situation, calculate height by using width so that both will fit to the screen
        small_width = this.container_width-2*this.padding_horizontal;
        small_height = small_width * (1/this.aspect_ratio);

      }

      // if the calculated width and height are too small (happens when Screen width is very very small),
      // then keep the width and height as constant
      if(small_width<48 || small_height<36){
        small_width = 48;
        small_height = 36;
      }

      left_top_margin = (this.container_height - (small_height*length + (length+1)*this.padding_vertical))/2;
      ls_left_margin = (this.container_width-small_width)/2;

    } 
    
    /*
    * 
    *  LANDSCAPE
    *  __________
    * |          |
    * |          |
    * |__________|
    *
    */
    else {
      isPortrait = false;
      this.isPortrait = false

      // left side's width is x, right side's width is 2x
      let left_side_width = this.container_width/3;
      let right_side_width = this.container_width*2/3;


      small_height = (this.container_height-(length-1)*this.padding_vertical)/vertical_ratio;
      small_width = small_height*this.aspect_ratio;
      
      // width overflow
      if(small_width>left_side_width-2*this.padding_horizontal) {

        // in this situation, calculate height by using width so that both will fit to the screen
        small_width = left_side_width-this.deafult_margin;
        small_height = small_width * (1/this.aspect_ratio);

      }

      // if the calculated width and height are too small (happens when Screen width is very very small),
      // then keep the width and height as constant
      if(small_width<48 || small_height<36){
        small_width = 48;
        small_height = 36;
      }

      left_top_margin = (this.container_height - (small_height*(length-1) + (length)*this.padding_vertical))/2;
      ls_left_margin = (left_side_width - small_width) / 2;

      big_height = this.container_height - 50;
      big_width = big_height*4/3;

      if(big_width > (right_side_width-2*this.padding_horizontal)){

        big_width = right_side_width - 2*this.padding_horizontal;
        big_height = big_width*(1/this.aspect_ratio);

      }

      // if the calculated width and height are too small (happens when Screen width is very very small),
      // then keep the width and height as constant
      if(big_width<48 || big_height<36){
        big_width = 48;
        big_height = 36;
      }

      right_top_margin = (this.container_height-big_height) / 2;
      rs_left_margin = left_side_width + this.padding_horizontal;

    }
    
    // now, iterate all the frames and set their size and location accordingly
    for(let i=0; i<this.frames.length; i++){

      let final_width;
      let final_height;
      let ls_top_of_frame;

      let index = this.frames[i].id;

      if(isPortrait) {

        // set its size as small
        this.frames[i].size = "small";

        // if the frame is a collaboration, hide all the buttons
        if(this.frames[i].isCollaboration){
          this.imageCollaborationService.hideButtons(true);
          this.pdfCollaborationService.hideButtons(true);
        }


        ls_top_of_frame = left_top_margin + (small_height*index)+(index+1)*this.padding_vertical;
        // now set all the calculated attributes of the frame if it is not fullscreen
        if (!this.frames[i].fullscreen) {
          this.frames[i].style={
            top:ls_top_of_frame+"px",
            height: small_height+"px",
            left:ls_left_margin+"px",
            width: small_width+"px"
          };
        }
        
        final_width = small_width;
        final_height = small_height;

      } 
      
      /*
      * 
      *  LANDSCAPE
      *  __________
      * |          |
      * |          |
      * |__________|
      *
      */
      else {
        // if the current frame is not the last frame it should be on the left side and be small, otherwise it will be on right and big
        
        /*************************************************************************************************************************/
        /*************************************************LEFT SIDE & SMALL*******************************************************/
        /*************************************************************************************************************************/
        if(this.frames[i].id!=(this.final_id-1)){
          
          // set its size as small
          this.frames[i].size = "small";
          
          // if the frame is a collaboration, hide all the buttons
          if(this.frames[i].isCollaboration){
            this.imageCollaborationService.hideButtons(true);
            this.pdfCollaborationService.hideButtons(true);
          }

          ls_top_of_frame = left_top_margin + (small_height*index+this.padding_vertical)+(index)*this.padding_vertical;

          // now set all the calculated attributes of the frame if it is not fullscreen
          if (!this.frames[i].fullscreen) {
            this.frames[i].style={
              top:ls_top_of_frame+"px",
              width: small_width+"px",
              height: small_height+"px",
              left: ls_left_margin+"px"
            };
          }
          
          final_width = small_width;
          final_height = small_height;
        }

        /*************************************************************************************************************************/
        /*************************************************RIGHT SIDE & BIG********************************************************/
        /*************************************************************************************************************************/
        else {
          // set its size as big
          this.frames[i].size = "big";
          
          // if the frame is a collaboration, show all the buttons
          if(this.frames[i].isCollaboration){
            this.imageCollaborationService.hideButtons(false);
            this.pdfCollaborationService.hideButtons(false);
          }

          // now set all the calculated attributes of the frame if it is not fullscreen
          // whenever the size of the right big screen changes, parameters below are also changed accordingly.
          if (!this.frames[i].fullscreen) {
            this.frames[i].style = {
              top:    right_top_margin  +"px",
              width:  big_width         +"px",
              height: big_height        +"px",
              left:   rs_left_margin    +"px"
            };
          }
          final_width   = small_width;
          final_height  = small_height;
        }

      }

      // change the dimension of the video box
      try {
        if(this.frames[i].stream){
          if(this.frames[i].stream.videoDimensions.width>this.frames[i].stream.videoDimensions.height){
            this.frames[i].stream.setVideoDimensions(final_width,final_height);
          }
        }
      } catch (error) {}

      // if there is only one video box in room
      if(this.frames.length==1 && !this.frames[0].fullscreen){
        let height = this.container_height-50;
        let width = height * this.aspect_ratio;

        //if width does not fit to the screen, calculate width first then set height accordingly
        if(width>this.container_width) {
          width = this.container_width-2*this.padding_horizontal;
          height = width*(1/this.aspect_ratio);
        }
        let left = (this.container_width-width)/2;
        let top = (this.container_height-height)/2
        this.frames[0].style={
          top:top+"px",
          width: width+"px",
          height: height+"px",
          left: left+"px"
        };
      }
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      }
    }
    setTimeout(() => {
      this.imageCollaborationService.triggerResize();
    }, 500);
    
  }

  changeFrame(index) {
    let frameChanged = this.frames[1]
    this.frames[1] = this.frames[index + 2]
    this.frames[index+2] = frameChanged
  }

  changeShowUserStatus() {
    this.showUsers = !this.showUsers
  }

  changeFrameLine(index) {
    if(this.frameCounter == this.frames.length-1) {
      this.frameCounter = 1
    }else{
      this.frameCounter ++
    }
    let nextStream =this.frames[1]
    this.frames.splice(1,1)
    this.frames.push(nextStream)
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    setTimeout(() => {
      const coll = this.frames.find(f => f.isCollaboration);
      if (coll) {
        if (coll.collaborationData.type === "image" || coll.collaborationData.type === "screenshot") {
          this.imageCollaborationService.triggerResize();
        } else if (coll.collaborationData.type === "image") {
          this.pdfCollaborationService.triggerResize();
        }
      }
    }, 500);
  }
}

