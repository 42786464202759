import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { FormControl, NgForm, Validators } from '@angular/forms';

import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { OpentokService } from '@services/core/opentok.service';
import { LogService } from '@services/core/log.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { ArCollaborationService } from '@services/core/ar-collaboration.service';
import { LoaderService } from '@services/support/loader.service';
import { UtilityService } from '@services/support/utility.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { ModalService } from '@services/support/modal.service';

import { first, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DbService } from '@services/core/db.service';
import { environment } from 'environments/environment';
import { MultilanguageService } from '@services/support/multilanguage.service';

import { Language } from '@models/Language';

@Component({
  selector: 'app-join-with-link',
  templateUrl: './join-with-link.component.html',
  styleUrls: ['./join-with-link.component.scss']
})
export class JoinWithLinkComponent implements OnInit, OnDestroy {

  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;
  @ViewChild("notSupportedTemplate", { static: true }) private notSupportedTemplate: TemplateRef<any>;
  @ViewChild("joinTemplate", { static: true }) private joinTemplate: TemplateRef<any>;
  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild("checkAgreementModel", {static: true}) private checkAgreementTemplate: TemplateRef<any>;
  @ViewChild("agreementModel", {static: true}) private agreementTemplate: TemplateRef<any>;
  @ViewChild("noDeviceTemplate", {static: true}) private noDeviceTemplate: TemplateRef<any>;

  loading: boolean = true;
  linkData: any = null;
  error: string = null;

  linkId: string = null;
  guestName: string = "";

  browserParser: any;
  showDownloadApp: boolean = false;

  loginInProgress: boolean = false;
  authSubScrition: Subscription = null;

  useChinaServer: boolean = false;

  currentLang: Language;
  allLangs: Language[] = [];
  showLangs: boolean = false;
  langSub: Subscription = null;

  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;

  publicLink = new FormControl('', Validators.required)

  moreOptionsOpen = false

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private callService: CallService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private utilityService: UtilityService,
    private flashMessageService: FlashMessageService,
    private multilanguageService: MultilanguageService,
    private serializer: UrlSerializer,
    /*** THIS SERVICES INJECTED FOR BOOTSTRAP ***/
    public opentokService: OpentokService,
    
    private logService: LogService,
    private collaborationService: CollaborationService,
    private arCollaborationService: ArCollaborationService
  ) { }

  ngOnInit() {
    this.useChinaServer = this.dbService.getSourceType() === "socket";

    // Navigate main if user already authenticated
    this.authSubScrition = this.authService.isAuthenticated().subscribe(auth => {
      if (auth) { this.authService.logout() }
    });

    this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
      this.cameraMirror = mirror;
    });

    let showDownloadAppParametric = true
    this.route.queryParams.pipe(first()).toPromise()
    .then(params => {
      if (params && params.id) {
        this.loaderService.show();

        if (this.authService.unifiedPanel) {
          setTimeout(() => {
            const tree = this.router.createUrlTree([], { queryParams: params });
            const url = environment.endPoints.workflowBase + this.serializer.serialize(tree);
            window.location.href = url;
          }, 1000);
        } else {
          this.authService.getJoinLinkData(params.id)
          .then(linkData => {
            this.linkId = params.id;
            this.linkData = linkData;
            if (!this.linkData.timezone) {
              this.linkData.timezone = "UTC";
            }
            if (this.linkData.auto_guest_name) {
              const randomNumber = (Date.now() % 10000)
              this.guestName = "Guest" + randomNumber;
              showDownloadAppParametric = false;
              this.showDownloadApp = false;
              this.onJoin();
            } else if (params && params.name) {
              this.guestName = params.name;
              this.onJoin();
            }
          })
          .catch(error => {
            if (error.error === 'session-not-found') {
              this.error = 'session-not-found';
            } else if (error.error === 'session-expired') {
              this.error = 'session-expired';
            } else {
              this.error = 'internal-error';
            }
          })
          .finally(() => {
            this.loaderService.hide();
            this.loading = false;
          });
        }
      } else {
        this.error = 'session-not-found';
        this.loading = false;
      }
    });

    this.publicLink.patchValue(window.location.href)

    this.browserParser = this.utilityService.getBrowserParser();
    const isTouchDevice = 'ontouchstart' in window;

    this.showDownloadApp = showDownloadAppParametric && environment.design.showAppBadgesOnGuestAccess && (this.browserParser.is("mobile") || this.browserParser.is("tablet") || (this.browserParser.is("macOS") && isTouchDevice));

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    });
  }

  cameraMirrorChanged(mirror: boolean) {
    this.opentokService.changeCameraMirror(mirror);
  }

  getMeetingDuration(start: number, end: number) {
    const i3 = Math.floor((end - start) / 60000);
    const h3 = Math.floor(i3/60);
    const m3 = i3 % 60;

    return `${h3}h ${m3}m`;
  }

  chinaServerChange(useChina: boolean) {
    this.dbService.setSourceType(useChina ? "socket" : "firebase");
  }

  onLanguageSelected(lang: Language) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  onJoin() {
    const isTouchDevice = 'ontouchstart' in window;
    // Is Device iOS or ipadOS (detected as touch macOS)
    const isIOS = this.browserParser.is("iOS");
    const isIpad = this.browserParser.is("macOS") && isTouchDevice;

    if (isIOS || isIpad) {
      if (!this.browserParser.is("Safari")) {
        this.showNotSupportedModal({ ios: true });
        return;
      } else if (!this.browserParser.satisfies({safari: ">=12"})) {
        this.showNotSupportedModal({ ios: true, ios_version_low: true });
        return;
      }
    }
    if (this.browserParser.is("Microsoft Edge") && !this.browserParser.satisfies({edge: '>=79' })) {
      this.showNotSupportedModal({ ios: false });
      return;
    }

    if (!(environment.design.supportedBrowserList.some(browserName => this.browserParser.is(browserName)) && this.utilityService.checkOpentokRequirements())) {
      this.showNotSupportedModal({ ios: false });
      return;
    }
    this.showJoinModal();
  }

  showNotSupportedModal(dataModel: any) {
    const modalId = this.modalService.show({
      template: this.notSupportedTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId)
        }
      }
    });
  }

  showJoinModal() {
    // @ts-ignore
    const AContext = window.AudioContext || window.webkitAudioContext;
    const model = {
      publishResolutions: ["320x180", "320x240", "640x360", "640x480", "1280x720", "1280x960"],
      currentStream: null,
      audios: [],
      videos: [],
      selectedVideo: null,
      selectedAudio: null,
      audioEnabled: true,
      videoEnabled: false,
            //@ts-ignore
      audioContext: new AContext(),
      audioLevel: 0,
      audioLevelSub: null,
      settingsOn: false,
      status: "waiting",
      enableJoin: false,
      enableCancel: false
    };
    const modalId = this.modalService.show({
      template: this.joinTemplate,
      context: {
        dataModel: model,
        callbacks: {
          toggleAudio: () => this.opentokService.togglePreviewAudio(model),
          toggleVideo: () => this.opentokService.togglePreviewVideo(model),
          changeAudioSource: (device: OT.Device) => this.opentokService.changePreviewSource("audio", model, this.videoElement),
          changeVideoSource: (device: OT.Device) => this.opentokService.changePreviewSource("video", model, this.videoElement),
          close: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            this.modalService.hide(modalId);
          },
          join: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            this.modalService.hide(modalId);
            if (model.status === 'no-devices-found') {
              this.showNoDeviceModal();
            } else {
              this.authService.addOns.pipe(first(), map(addOns => addOns.agreement)).toPromise()
              .then(showAgreement => {
                if (showAgreement) {
                  this.showAgreement();
                }
              })
              this.join(false);
            }
          }
        }
      }
    });

    setTimeout(() => {
      this.opentokService.startPreview(model, this.videoElement);
    }, 1000);
  }

  showNoDeviceModal() {
    const modalId = this.modalService.show({
      template: this.noDeviceTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
            this.showJoinModal();
          },
          join: () => {
            this.modalService.hide(modalId);
            this.authService.addOns.pipe(first(), map(addOns => addOns.agreement)).toPromise()
            .then(showAgreement => {
              if (showAgreement) {
                this.showAgreement();
              }
            })
            this.join(false);
          }
        }
      }
    });
  }

  join(archiveAllowed: boolean) {
    this.loaderService.show();
    this.loginInProgress = true;
    this.authService.joinWithLink(this.linkId, this.guestName)
    .then(result => {
      if (this.authSubScrition) { this.authSubScrition.unsubscribe() }
      return (this.authService.loginWithLink(result.token).then(() => result));
    })
    .then(result => this.callService.joinRoom(result.data.room_id, archiveAllowed))
    .then(() => this.router.navigate(['/room']))
    .catch(error => {
      if (error.message === 'session-not-found') {
        this.error = 'session-not-found';
        this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.SESSION_NOT_FOUND');
      } else if (error.message === 'session-expired') {
        this.error = 'session-expired';
        this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.SESSION_NOT_AVAILABLE');
      } else if (error.message === 'concurrent-limit-reached') {
        this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.CONCURRENT_LIMIT_REACHED');
      } else if (error.message === 'expert-concurrent-limit-reached') {
        this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.EXPERT_CONCURRENT_LIMIT_REACHED');
      } else if (error.message === 'no-training-license') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.NO_TRAINING_LICENSE');
      } else if (error.message === 'training-license-expired') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TRAINING_LICENSE_EXPIRED');
      } else if (error.message === 'training-license-error') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.ASK_TRAINING_HOST');
      } else if (error.message === 'archive-permission-needed') {
        this.showArchivePermissionModal();
      } else {
        this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.UNKNOWN_ERROR');
      }
    })
    .finally(() => {
      this.loginInProgress = false;
      this.loaderService.hide();
    });
  }

  showAgreement() {
    const modalId = this.modalService.show({
      template: this.agreementTemplate,
      context: {
        dataModel: null,
        callbacks: {
          reject: () => {
            this.showCheckAgreement(modalId);
          },
          accept: () => {
            this.loaderService.show();
            this.authService.acceptAgreement()
            .then(() => {
              this.modalService.hide(modalId);
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.ERROR')) //There is an error occured
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  showCheckAgreement(agreementModalId: number) {
    const modalId = this.modalService.show({
      template: this.checkAgreementTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          },
          proceed: () => {
            this.loaderService.show();
            this.logout()
            .then(() => {
              this.modalService.hide(modalId)
              this.modalService.hide(agreementModalId)
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.LOGOUT_FAILED'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  logout(): Promise<void> {
    if (this.callService.inRoom) {
      return this.callService.endCall()
      .then(() => {
        return this.authService.logout();
      });
    } else {
      // For prevent loader flashing
      return new Promise(resolve => setTimeout(resolve, 500))
      .then(() => {
        return this.authService.logout();
      });
    }
  }

  showArchivePermissionModal() {
    const modalId = this.modalService.show({
      template: this.archivePermissionModal,
      context: {
        dataModel: null,
        callbacks: {
          deny: () => this.modalService.hide(modalId),
          allow: () => {
            this.join(true);
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.authSubScrition) { this.authSubScrition.unsubscribe() }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
  }

  onTermsofService() {
    let url = window.location.origin+"/terms";

    const termsUrls = environment.design['termsUrls'];
    const defaultTermsLang = environment.design['defaultTermsLang'];
    if (termsUrls) {
      if (termsUrls[this.multilanguageService.currentLang.code]) {
        url = termsUrls[this.multilanguageService.currentLang.code];
      } else if (defaultTermsLang && termsUrls[defaultTermsLang]) {
        url = termsUrls[defaultTermsLang];
      }
    }
    window.open(url);
  }

  onPrivacyPolicy() {
    let url = window.location.origin+"/privacy";

    const privacyUrls = environment.design['privacyUrls'];
    const defaultPrivacyLang = environment.design['defaultPrivacyLang'];
    if (privacyUrls) {
      if (privacyUrls[this.multilanguageService.currentLang.code]) {
        url = privacyUrls[this.multilanguageService.currentLang.code];
      } else if (defaultPrivacyLang && privacyUrls[defaultPrivacyLang]) {
        url = privacyUrls[defaultPrivacyLang];
      }
    }
    window.open(url);
  }

  clickStoreLink(link) {
    link.select()
    document.execCommand('copy');
  }
}
