import { Component, OnInit, Input , Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';

import { CallService } from '@services/core/call.service';
import { OpentokService } from '@services/core/opentok.service';
import { LogService } from '@services/core/log.service';
import { FileShareService } from '@services/other/file-share.service';
import { ArCollaborationService } from '@services/core/ar-collaboration.service';
import { FullscreenService } from '@services/support/fullscreen.service';
import { SubscriberService } from '@services/core/subscriber.service';
import { DbService } from '@services/core/db.service';

//Rxjs imports
import { Subscription ,  interval } from 'rxjs';
import { map } from 'rxjs/operators';

//Models imports
import { Frame } from '@models/Frame';
import { UploadFile } from '@models/UploadFile';
import { ArDot } from '@models/ArDot';
import { AuthService } from '@services/core/auth.service';
import { RoomSessionService } from '@services/core/room-session.service';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss']
})
export class PublisherComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild('publisherContainer', { static: true }) publisherContainer: ElementRef;
  @Input('frame') frame: Frame;
  @Input('size') size: string;
  @Input('nohover') nohover: boolean = false;
  @Input('isMobileScreen') isMobileScreen: boolean = false;

  @Output() toggleFullscreen = new EventEmitter<boolean>();

  publisher: OT.Publisher;
  publisherData: any = null;

  cameraSub: Subscription = null;

  screenPublishing: boolean = false;
  screenSub: Subscription = null;

  signalState: number = 0;
  audioKbps: number = 0;
  videoKbps: number = 0;

  differenceVideoPacketLossRatio: number = 0;
  differenceAudioPacketLossRatio: number = 0;

  videoPacketLossRatio: number = 0;
  audioPacketLossRatio: number = 0;

  minStatsVideo: number = 0;
  minStatsAudio: number = 0;

  listenStatsSub: Subscription = null;

  showStatsDetails: boolean = false;
  
  screenWidth: number = 0;
  screenHeight: number = 0;
  screenWidthDynamic: number = 0;
  screenHeightDynamic: number = 0;

  //BP: Break Point
  minBPVideoLossRatio: number = 0;
  maxBPVideoLossRatio: number = 0;
  minBPAudioLossRatio: number = 0;
  maxBPAudioLossRatio: number = 0;

  audioBPMax: number = 0;
  audioBPMid: number = 0;
  audioBPMin: number = 0;

  logsSub: Subscription = null;
  currentOriginalText: any = null;
  currentTranslation: any = null;

  fullscreenState: {fullscreen: boolean, supported: boolean} = {fullscreen: false, supported: true};
  fullscreenSub: Subscription = null;

  audioAvailable: boolean = false;
  private publishAudioSub: Subscription = null;
  videoAvailable: boolean = false;
  private publishVideoSub: Subscription = null;

  collaborationRequestSub: Subscription = null;

  arDots: ArDot[] = [];
  arCollSub: Subscription = null;

  pipEnabled: boolean = false;
  _document: any = document;

  continueToTryPublish: boolean = true;

  audioLevelIndicatorStyle = {width: "0px"};
  movingAvg = null;

  addOnsSub: Subscription = null;

  audioDevices: any[] = [];

  publisherMuted: boolean = false;

  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;
/*
  subtitle: string = null
  private subtitleSub: Subscription = null;
*/
  constructor(
    public opentokService: OpentokService,

    private roomSessionService: RoomSessionService,
    private callService: CallService,
    private logService: LogService,
    private fileShareService: FileShareService,
    private arCollaborationService: ArCollaborationService,
    private changeDetector: ChangeDetectorRef,
    private fullscreenService: FullscreenService,
    private subscribeService: SubscriberService,
    private dbService: DbService,
    private authService: AuthService
  ) { }

  ngOnInit() {
/*
    this.subtitleSub = this.dbService.listen<string>(`accounts/${this.authService.currentUser.account_id}/sessions/${this.roomSessionService.currentRoomId}/${this.roomSessionService.currentSessionId}/transcription/${this.authService.currentUser.id}`)
    .subscribe(text => {
      if (text) {
        this.subtitle = text.length > 60 ? "..." + text.substring(text.length - 57) : text
      }
    })
*/
    this.fullscreenSub = this.fullscreenService.isFullscreen.subscribe(state => {
      this.fullscreenState = state;
      this.changeDetector.detectChanges();
    });

    this.addOnsSub = this.authService.addOns.subscribe(addOns => {
      if (addOns) { 
        this.showStatsDetails = addOns.detailedvideoquality; 
      }
    });

    if (this._document.pictureInPictureEnabled) {
      this.pipEnabled = true;
    }
    this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
      this.cameraMirror = mirror;
      this.changeDetector.detectChanges();
    });

    this.collaborationRequestSub = this.logService.onSreenshotRequest.subscribe(message => {
      const imgData = this.publisher.getImgData();
      this.sendSnapshot(imgData);
    });

    this.audioDevices = this.opentokService.getAvailableDevices().filter(d => d.kind === "audioInput");

    this.screenSub = this.opentokService.screenPublishState.subscribe(state => {
      this.screenPublishing = state;
      this.changeDetector.detectChanges();

      if (this.screenPublishing && this._document.pictureInPictureElement && this._document.exitPictureInPicture) {
        this._document.exitPictureInPicture();
      }

      if (this.screenPublishing) {
        if (this.publisher) {
          this.publisher.off("audioLevelUpdated", this.audioLevelUpdated);
        }
        if (this.opentokService.currentScreenPublisher) {
          if (this.audioDevices.length !== 0) {
            this.opentokService.currentScreenPublisher.on("audioLevelUpdated", this.audioLevelUpdated);
          }
        }
        if (this.listenStatsSub) { this.listenStatsSub.unsubscribe() }
        this.listenStatsSub = this.listenStats(this.opentokService.currentScreenPublisher);
      } else {
        if (this.opentokService.currentScreenPublisher) {
          this.opentokService.currentScreenPublisher.off("audioLevelUpdated", this.audioLevelUpdated);
        }
      }
    });

    this.publishAudioSub = this.opentokService.publishAudio.subscribe(publishAudio => {
      this.publisherMuted = !publishAudio;
      if (!publishAudio) {
        this.audioAvailable = false;
      }
    });
    this.publishVideoSub = this.opentokService.publishVideo.subscribe(publishVideo => {
      if (!publishVideo) {
        this.videoAvailable = false;
      }
    });

    let timer = null
    const date = Date.now()
    this.logsSub = this.dbService.querySnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${this.roomSessionService.currentRoomId}/${this.roomSessionService.currentSessionId}/transcriptions`, [{key:"orderByKey",value:null}, {key:"limitToLast",value:1}], "child_added")
    .subscribe(snp => {
      const log = snp.data;
      log.id = snp.key;

      if (this.authService.currentUser.id === log.owner && Date.now() - date > 500) {
        this.currentTranslation = log.translation;
        this.currentOriginalText = log.content;
        const duration = Math.min(Math.max(this.currentTranslation.length * 100, 1000), 4000);
        console.log(this.currentTranslation.length * 200)
  
        if (timer) { clearTimeout(timer) }
        timer = setTimeout(() => {
          this.currentTranslation = null;
          this.currentOriginalText = null;
          timer = null;
        }, duration);
      }
    });
  }

  audioLevelUpdated = (event: OT.Event<"audioLevelUpdated", OT.Publisher> & { audioLevel: number; }) => {
    if(this.frame.style2) {
      if (this.movingAvg === null || this.movingAvg <= event.audioLevel) {
        this.movingAvg = event.audioLevel;
      } else {
        this.movingAvg = 0.7 * this.movingAvg + 0.3 * event.audioLevel;
      }

      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      var logLevel = (Math.log(this.movingAvg) / Math.LN10) / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      this.audioLevelIndicatorStyle.width = Math.floor(logLevel * 100) + "px";
      if (logLevel > 0.4) {
        this.frame.style2["box-shadow"] = `0px 0px 4px 4px rgb(69 130 179)`;
      } else if (logLevel > 0.2) {
        this.frame.style2["box-shadow"] = `0px 0px 4px 2px rgb(69 130 179)`;
      } else {
        this.frame.style2["box-shadow"] = '0px 3px 6px rgb(0, 0, 0)';
      }
    }
  }

  listenStats(publisher: OT.Publisher) {
    
    this.signalState = 0; //0 --> bad, 1 --> ok, 2 --> good, 3 --> excellent

    this.minBPAudioLossRatio = 0.005;
    this.maxBPAudioLossRatio = 0.05;
    this.minBPVideoLossRatio = 0.005;
    this.maxBPVideoLossRatio = 0.03;

    const lastStats: any = {}
    return interval(5000).subscribe(() => {
      publisher.getStats((statsError, stats) => {
        if (statsError) {
          console.log(statsError);
          return;
        }

        if (!this.dbService.connected()) {
          this.videoAvailable = false;
          this.audioAvailable = false;
          
          this.signalState = 0;
          this.minStatsVideo = 0;
          this.minStatsAudio = 0;
          this.audioPacketLossRatio = 0;
          this.videoPacketLossRatio = 0;
          return;
        }
        
        this.screenWidth = publisher.stream.videoDimensions.width;
        this.screenHeight = publisher.stream.videoDimensions.height;
        this.screenWidthDynamic = publisher.videoWidth();
        this.screenHeightDynamic = publisher.videoHeight();

        this.videoAvailable = publisher.stream.hasVideo;
        this.audioAvailable = publisher.stream.hasAudio; 
        
        let timestamp = 0;
        this.minStatsAudio = 10000;
        this.minStatsVideo = 10000;

        const statMap: any = {};
        stats.forEach(stat => {
          statMap[stat.subscriberId] = stat;
          if (!lastStats[stat.subscriberId]) {
            lastStats[stat.subscriberId] = {
              lastVideoPacketsLost: 0,
              lastVideoPacketsSent: 0,
              lastAudioPacketsLost: 0,
              lastAudioPacketsSent: 0,
              lastAudioBytesSent: 0,
              lastVideoBytesSent: 0,
              lastTimestamp: 0
            }
          }
        });

        const lastSubscriberIds = Object.keys(lastStats);
        for (const sId of lastSubscriberIds) {
          const subscriberDropped = lastStats[sId] && !statMap[sId]
          if (subscriberDropped) {
            delete lastStats[sId]
          }
          //const newSubscriber = !lastStats[sId] && statMap[sId];
        }

        const subscriberIds = Object.keys(statMap);
        for (const sId of subscriberIds) {
          let stat = statMap[sId];
          let lastStat = lastStats[sId];
          timestamp = stat.stats.timestamp / 1000;
          if (lastStat.lastTimestamp !== 0) {

            stat.videoPacketsSent = stat.stats.video?.packetsLost + stat.stats.video?.packetsSent;
            stat.videoPacketsLost = stat.stats.video?.packetsLost;

            stat.videoPacketsSentDifference = stat.videoPacketsSent - lastStat.lastVideoPacketsSent;
            stat.videoPacketsLostDifference = stat.videoPacketsLost - lastStat.lastVideoPacketsLost;

            stat.audioPacketsSent = stat.stats.audio?.packetsLost + stat.stats.audio?.packetsSent;
            stat.audioPacketsLost = stat.stats.audio?.packetsLost;

            stat.audioPacketsSentDifference = stat.audioPacketsSent - lastStat.lastAudioPacketsSent;
            stat.audioPacketsLostDifference = stat.audioPacketsLost - lastStat.lastAudioPacketsLost;
          
            this.videoKbps = Math.floor(8 * (stat.stats.video?.bytesSent - lastStat.lastVideoBytesSent) / (1024 * (timestamp - lastStat.lastTimestamp))); 
            if(this.videoKbps < this.minStatsVideo && (this.videoKbps !== 0)) {
              this.minStatsVideo = this.videoKbps;
              if (stat.videoPacketsSentDifference > 0) {
                this.videoPacketLossRatio = parseFloat((stat.videoPacketsLostDifference / stat.videoPacketsSentDifference).toFixed(4));
              } else {
                this.videoPacketLossRatio = 0;
              }
            }
          
            this.audioKbps = Math.floor(8 * (stat.stats.audio?.bytesSent - lastStat.lastAudioBytesSent) / (1024 * (timestamp - lastStat.lastTimestamp)));
            if (this.audioKbps < this.minStatsAudio && (this.audioKbps !== 0)) {
              this.minStatsAudio = this.audioKbps;
              if (stat.audioPacketsSentDifference > 0) {
                this.audioPacketLossRatio = parseFloat((stat.audioPacketsLostDifference / stat.audioPacketsSentDifference).toFixed(4));
              } else {
                this.audioPacketLossRatio = 0;
              }
            }

            lastStat.lastVideoPacketsSent = stat.videoPacketsSent;
            lastStat.lastVideoPacketsLost = stat.videoPacketsLost;
            
            lastStat.lastAudioPacketsSent = stat.audioPacketsSent;
            lastStat.lastAudioPacketsLost = stat.audioPacketsLost;
          }

          lastStat.lastAudioBytesSent = stat.stats.audio?.bytesSent;
          lastStat.lastVideoBytesSent = stat.stats.video?.bytesSent;
          lastStat.lastTimestamp = timestamp;
        }
        
        let breakpoints = {
          max: {res1920x1080: 0, res1280x720: 0, res640x480: 0, res352x288: 0, res320x240: 0}, 
          mid: {res1920x1080: 0, res1280x720: 0, res640x480: 0, res352x288: 0, res320x240: 0}, 
          min: {res1920x1080: 0, res1280x720: 0, res640x480: 0, res352x288: 0, res320x240: 0}
        }
        if (!this.screenPublishing) {
          breakpoints = {
            max: {
              res1920x1080: 1000,
              res1280x720: 1000,
              res640x480: 600,
              res352x288: 300,
              res320x240: 300
            },
            mid: {
              res1920x1080: 675,
              res1280x720: 675,
              res640x480: 425,
              res352x288: 225,
              res320x240: 225
            },
            min: {
              res1920x1080: 350,
              res1280x720: 350,
              res640x480: 250,
              res352x288: 150,
              res320x240: 150
            }
          }
        } else {
          breakpoints = {
            max: {
              res1920x1080: 500,
              res1280x720: 300,
              res640x480: 150,
              res352x288: 100,
              res320x240: 100
            },
            mid: {
              res1920x1080: 300,
              res1280x720: 150,
              res640x480: 100,
              res352x288: 50,
              res320x240: 50
            },
            min: {
              res1920x1080: 100,
              res1280x720: 50,
              res640x480: 50,
              res352x288: 25,
              res320x240: 25
            }
          }
        }

        //Calculating signal state only
        if (this.screenPublishing || this.videoAvailable) {
          if (this.screenWidth*this.screenHeight >= 1920*1080) {
            if (this.minStatsVideo > breakpoints.max.res1920x1080) {
              if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                  this.signalState = 3;
              } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                  this.signalState = 2;
              }
            }
            else if (this.minStatsVideo > breakpoints.mid.res1920x1080 && this.minStatsVideo <= breakpoints.max.res1920x1080) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 2;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 1;
              }
            }  
            else if (this.minStatsVideo > breakpoints.min.res1920x1080 && this.minStatsVideo <= breakpoints.mid.res1920x1080) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 1;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 0;
              }
            } 
            else if (this.minStatsVideo <= breakpoints.min.res1920x1080) {
              this.signalState = 0;
            }
          }
          else if (this.screenWidth*this.screenHeight >= 1280*720 && this.screenWidth*this.screenHeight < 1920*1080) {
            if (this.minStatsVideo > breakpoints.max.res1280x720) {
              if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                  this.signalState = 3;
              } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                  this.signalState = 2;
              }
            }
            else if (this.minStatsVideo > breakpoints.mid.res1280x720 && this.minStatsVideo <= breakpoints.max.res1280x720) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 2;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 1;
              }
            }  
            else if (this.minStatsVideo > breakpoints.min.res1280x720 && this.minStatsVideo <= breakpoints.mid.res1280x720) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 1;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 0;
              }
            } 
            else if (this.minStatsVideo <= breakpoints.min.res1280x720) {
              this.signalState = 0;
            }
          }
          else if (this.screenWidth*this.screenHeight >= 640*480 && this.screenWidth*this.screenHeight < 1280*720) {
            if (this.minStatsVideo > breakpoints.max.res640x480) {
              if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                this.signalState = 3;
              } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                this.signalState = 2;
              }
            }
            else if (this.minStatsVideo > breakpoints.mid.res640x480 && this.minStatsVideo <= breakpoints.max.res640x480) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 2;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 1;
              }
            } 
            else if (this.minStatsVideo > breakpoints.min.res640x480 && this.minStatsVideo <= breakpoints.mid.res640x480) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 1;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 0;
              }
            } 
            else if (this.minStatsVideo <= breakpoints.min.res640x480) {
              this.signalState = 0;
            }
          } 
          else if (this.screenWidth*this.screenHeight >= 352*288 && this.screenWidth*this.screenHeight < 640*480) {
            if (this.minStatsVideo > breakpoints.max.res352x288) {
              if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                this.signalState = 3;
              } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                this.signalState = 2;
              }
            } 
            else if (this.minStatsVideo > breakpoints.mid.res352x288 && this.minStatsVideo <= breakpoints.max.res352x288) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 2;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 1;
              } 
            }
            else if (this.minStatsVideo > breakpoints.min.res352x288 && this.minStatsVideo <= breakpoints.mid.res352x288) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio){
                this.signalState = 1;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 0;
              } 
            }
            else if (this.minStatsVideo <= breakpoints.min.res352x288) {
              this.signalState = 0;
            } 
          }         
          else if (this.screenWidth*this.screenHeight >= 320*240 && this.screenWidth*this.screenHeight < 352*288) {  
            if (this.minStatsVideo > breakpoints.max.res320x240) {
              if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                this.signalState = 3;
              } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                this.signalState = 2;
              }
            }
            else if (this.minStatsVideo > breakpoints.mid.res320x240 && this.minStatsVideo <= breakpoints.max.res320x240) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 2;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 1;
              }
            }
            else if (this.minStatsVideo > breakpoints.min.res320x240 && this.minStatsVideo <= breakpoints.mid.res320x240) {
              if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                this.signalState = 1;
              } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                this.signalState = 0;
              }
            } 
            else if (this.minStatsVideo <= breakpoints.min.res320x240) {
              this.signalState = 0;
            }
          }  
        }
        else if (!this.screenPublishing && !this.videoAvailable) {
          this.minStatsVideo = 0;
          this.videoPacketLossRatio = 0;
        }
        
        if (!this.screenPublishing && !this.videoAvailable && this.audioAvailable) {
          this.audioBPMax = 20;
          this.audioBPMid = 15;
          this.audioBPMin = 10;
          if (this.minStatsAudio > this.audioBPMax) {
            if (this.audioPacketLossRatio < this.minBPAudioLossRatio) {
              this.signalState = 3;
            } else if (this.audioPacketLossRatio >= this.minBPAudioLossRatio) {
              this.signalState = 2;
            }
          } 
          else if (this.minStatsAudio > this.audioBPMid && this.minStatsAudio <= this.audioBPMax) {
            if (this.audioPacketLossRatio < this.maxBPAudioLossRatio) {
              this.signalState = 2;
            } else if (this.audioPacketLossRatio >= this.maxBPAudioLossRatio) {
              this.signalState = 1;
            }
          }
          else if (this.minStatsAudio > this.audioBPMin && this.minStatsAudio <= this.audioBPMid) {
            if (this.audioPacketLossRatio < this.maxBPAudioLossRatio) {
              this.signalState = 1;
            } else if (this.audioPacketLossRatio >= this.maxBPAudioLossRatio) {
              this.signalState = 0;
            }
          }
          else if (this.minStatsAudio <= this.audioBPMin) {
            this.signalState = 0;
          }
        } 
        else if (!this.audioAvailable) {
          this.minStatsAudio = 0;
          this.audioPacketLossRatio = 0;
        }

        if (!this.publisherData) {
          this.publisherData = JSON.parse(publisher.stream.connection.data);
        }
        this.subscribeService.setPublisherStats(this.publisherData.uid, {
          audio: this.minStatsAudio,
          video: this.minStatsVideo,
          signalState: this.signalState,
          videoPacketLossRatio: this.videoPacketLossRatio,
          audioPacketLossRatio: this.audioPacketLossRatio
        });
      })
    })
  }

  async sendSnapshot(imgData: string) {
    const d = new Date();
    const dateString = d.getFullYear() + "-" +
      ("0"+(d.getMonth()+1)).slice(-2) + "-" +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + "-" +
      ("0" + d.getMinutes()).slice(-2) + "-" +
      ("0" + d.getSeconds()).slice(-2) + "-" +
      d.getMilliseconds()

    // First try to convert PNG to JPEG
    const jpegFilename =  "VS_" + dateString + '.jpg';
    let file = await this.fileShareService.convertBase64toJpeg('data:image/png;base64,' + imgData, this.publisher.videoWidth(), this.publisher.videoHeight(), 0.9, jpegFilename);
    let upload: UploadFile;
    if (file) {
      // If conversion succeeded, create upload object
      upload = {
        file: file,
        extension: "jpg",
        file_type: 'image',
        progress: 0
      }
    } else {
      // If conversion fails, upload PNG file
      const pngFilename =  "VS_" + dateString + '.png';
      file = this.fileShareService.convertBase64toFile(imgData, 'image/png', pngFilename);
      upload = {
        file: file,
        extension: "png",
        file_type: 'image',
        progress: 0
      }
    }
    return this.fileShareService.uploadSnapshot(upload)
    .then(up1 => this.fileShareService.saveUserFileToChatDb(this.fileShareService.createDatabaseUserFile(up1)))
    .then(up2 => this.logService.sendShareScreenshotLog(up2));
  }

  ngAfterViewInit() {
    if (this.frame.type === "publisher2") {
      return;
    }
    this.cameraSub = this.opentokService.cameraPublishRequest.subscribe(() => {
      this.tryToPublish(3)
      .catch(error => {
        // Catch opentok errors first
        if (error){
          this.callService.otPublisherFailed(error.name, error.code);
        }
        return null;
      })
      .then(publisher => {
        if (publisher) {
          this.publisher = publisher;
          //Publisher data fullfills
          this.initializePublisherListeners();

          this.subscribeService.setPublisherStats(this.publisherData.uid, null);

          if (this.listenStatsSub) { this.listenStatsSub.unsubscribe() }
          this.listenStatsSub = this.listenStats(publisher);
        }
      })
      .catch(error => {
        // Catch errors in then block
        console.log(error);
      });
    });
  }

  async tryToPublish(maxTry: number) {
    let publisher = null;
    let error = null;
    let retryCount = 0;
    while (this.continueToTryPublish && !publisher && retryCount < maxTry) {
      publisher = await (this.opentokService.startCameraPublish(this.publisherContainer)
      .catch((err: OT.OTError) => {
        this.opentokService.saveOtError("publisher", err);
        error = err;
        if (!this.isRetryRequired(err.name)) {
          retryCount = maxTry;
        }
        return null;
      }));
      retryCount = retryCount + 1;
    }
    if (this.continueToTryPublish && !publisher) {
      throw error;
    }
    return publisher;
  }

  isRetryRequired(errorName: string) {
    return errorName === 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR' ||
      errorName === 'OT_CONSTRAINTS_NOT_SATISFIED' ||
      errorName === 'OT_CREATE_PEER_CONNECTION_FAILED' ||
      errorName === 'OT_ICE_WORKFLOW_FAILED' ||
      errorName === 'OT_MEDIA_ERR_ABORTED' ||
      errorName === 'OT_MEDIA_ERR_DECODE' ||
      errorName === 'OT_MEDIA_ERR_NETWORK' ||
      errorName === 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED' ||
      errorName === 'OT_NO_VALID_CONSTRAINTS' ||
      errorName === 'OT_NOT_CONNECTED' ||
      errorName === 'OT_NOT_SUPPORTED' ||
      errorName === 'OT_PERMISSION_DENIED' ||
      errorName === 'OT_SET_REMOTE_DESCRIPTION_FAILED' ||
      errorName === 'OT_STREAM_CREATE_FAILED' ||
      errorName === 'OT_TIMEOUT' ||
      errorName === 'OT_USER_MEDIA_ACCESS_DENIED' ||
      errorName === 'OT_UNEXPECTED_SERVER_RESPONSE' ||
      errorName === 'OT_INVALID_PARAMETER' ||
      errorName === 'OT_MEDIA_ERR_ABORTED' ||
      errorName === 'OT_MEDIA_ERR_DECODE' ||
      errorName === 'OT_MEDIA_ERR_NETWORK' ||
      errorName === 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED' ||
      errorName === 'OT_NOT_SUPPORTED'
  }

  initializePublisherListeners() {
    this.publisherData = JSON.parse(this.publisher.stream.connection.data);
    this.changeDetector.detectChanges();

    if (this.audioDevices.length !== 0){
      this.publisher.on("audioLevelUpdated", this.audioLevelUpdated)
    }

    this.videoAvailable = this.publisher.stream.hasVideo;
    this.audioAvailable = this.publisher.stream.hasAudio;

    if (this.frame.type === "publisher2") {
      return;
    }

    if (this.arCollSub) { this.arCollSub.unsubscribe() }
    this.arCollSub = this.arCollaborationService.getPublisherDots(this.publisherData.uid)
    .pipe(
      map(dots => {
        return !dots ? [] :
        Object.values(dots).filter(x => x !== '')
        .map((x: string) => {
          const values = x.split('!');
          let left = this.cameraMirror ? (100-parseFloat(values[1])) : parseFloat(values[1]);
          let top = parseFloat(values[2]);
          let temp_left = left+"%";
          let temp_top = top+"%";
          
          const arDot: ArDot = {
            colorCode: values[0],
            left: temp_left,
            top: temp_top,
            name: values[3],
            shape: values[4]
          }
          return arDot;
        })
      })
    )
    .subscribe(dots => {
      this.arDots = dots.map(dot => {
        dot.color = ArCollaborationService.arColorNames[dot.colorCode];
        return dot;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size) {
      this.size = changes.size.currentValue;
    }
    if (changes.isMobileScreen) {
      this.isMobileScreen = changes.isMobileScreen.currentValue;
    }
  }

  ngOnDestroy() {
    this.continueToTryPublish = false;
    if (this.fullscreenSub) { this.fullscreenSub.unsubscribe(); }
    if (this.cameraSub) { this.cameraSub.unsubscribe(); }
    if (this.screenSub) { this.screenSub.unsubscribe(); }
    if (this.arCollSub) { this.arCollSub.unsubscribe() }
    if (this.publishAudioSub) { this.publishAudioSub.unsubscribe() }
    if (this.publishVideoSub) { this.publishVideoSub.unsubscribe() }
    if (this.collaborationRequestSub) { this.collaborationRequestSub.unsubscribe(); }
    if (this.publisher) {
      this.publisher.off("audioLevelUpdated", this.audioLevelUpdated);
    }
    if (this.logsSub) { this.logsSub.unsubscribe() }
    if (this.listenStatsSub) { this.listenStatsSub.unsubscribe() }
    if (this.addOnsSub) { this.addOnsSub.unsubscribe() }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
    //if (this.subtitleSub) { this.subtitleSub.unsubscribe() }

    if (this._document.pictureInPictureElement && this._document.exitPictureInPicture) {
      this._document.exitPictureInPicture();
    }
    this.opentokService.destroyCurrentPublisherIfExists();
  }

  onToggleFullscreen() {
    if (this.fullscreenState.fullscreen) {
      this.toggleFullscreen.emit(false);
    } else {
      this.toggleFullscreen.emit(true);
    }
  }

  onTogglePictureInPicture() {
    const d: any = document;
    if (d.pictureInPictureElement) {
      if (d.exitPictureInPicture) {
        d.exitPictureInPicture();
      }
    } else {
      const videoElement = this.publisherContainer.nativeElement.getElementsByClassName("OT_video-element").item(0);
      if (videoElement) {
        if (videoElement.requestPictureInPicture) {
          videoElement.requestPictureInPicture()
          .catch(error => {
            // Video failed to enter Picture-in-Picture mode.
          });
        }
      }
    }
  }

  stopSharingScreen() {
    this.opentokService.endScreenSharing();
  }
}
