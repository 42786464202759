import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { LoaderService } from '@services/support/loader.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-ad-login',
  templateUrl: './ad-login.component.html',
  styleUrls: ['./ad-login.component.scss']
})
export class AdLoginComponent implements OnInit, OnDestroy {

  loading: boolean = true;
  authSubscription: Subscription = null;

  redirectToAppSelection: boolean = false;

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // Navigate main if user already authenticated
    this.authSubscription = this.dbService.authState.subscribe(auth => {
      if (auth) {
        if (this.redirectToAppSelection) {
          this.router.navigate(['/app-select']);
        } else {
          this.router.navigate(['/']);
        }
      }
    });
    this.authenticate();
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe(); }
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.token)) {
      this.loading = false;
      return;
    }

    if (params && params.admin) {
      this.redirectToAppSelection = true;
    }

    this.loaderService.show();
    const token = atob(decodeURIComponent(params.token));

    this.authService.login(token)
    .catch(error => { this.loading = false; })
    .finally(() => this.loaderService.hide());
  }
}
