<div class="publisher-wrapper" [class.small]="size === 'small' && !fullscreenState.fullscreen" [ngStyle]="{'background-color': frame.type === 'publisher2' ? '#ffffff' : 'transparent'}">
    <div class="publisher-container" #publisherContainer></div>

    <div class="audio-level-indicator" [ngStyle]="audioLevelIndicatorStyle"></div>

    <ng-container *ngIf="frame.type === 'publisher2'">
        <div *ngIf="currentOriginalText" class="translate-text translate-top-text d-flex flex-column">
            <div class="font-weight-bold">[ORIGINAL]</div>
            <div>{{ currentOriginalText }}</div>
        </div>
        <div class="translate-middle-bubble">
            <div class="translate-second-bubble">
                <div class="translate-third-bubble" style="padding-top: 2px; padding-left: 2px;">
                    <img src="assets/transcribe-icon-white.svg">
                </div>
            </div>
        </div>
        <div *ngIf="currentTranslation" class="translate-text translate-bottom-text d-flex flex-column">
            <div class="font-weight-bold">[TRANSLATION]</div>
            <div>{{ currentTranslation }}</div>
        </div>
    </ng-container>

    <div *ngIf="frame.type === 'publisher2'" class="controls-container d-flex justify-content-between align-items-center px-2"
            style="background-color: #4582b3;">
        <div style="overflow: hidden;" class="d-flex align-items-center">
            <div class="publisher-name">Translator for You</div>
        </div>
        <div class="controls">
            <a class="control d-flex align-items-center ml-2" (click)="onToggleFullscreen()" *ngIf="!isMobileScreen">
                <i class="mi" [ngClass]="{'mi-fullscreen': !fullscreenState.fullscreen, 'mi-fullscreen-exit': fullscreenState.fullscreen}"></i>
            </a>
        </div>
    </div>

    <div *ngIf="frame.type === 'publisher'" class="controls-container d-flex justify-content-between align-items-center px-2">
        <div style="overflow: hidden;" class="d-flex align-items-center">
            <a class="signal-button mr-2" *ngIf='!showStatsDetails' 
                [ngClass]="{'data-available': videoAvailable || audioAvailable, 
                            'excellent': signalState === 3, 'good': signalState === 2, 'ok': signalState === 1, 'bad': signalState === 0}">
                <img src="assets/signal2.svg">
            </a>
            <a class="signal-button mr-2" *ngIf="showStatsDetails"
                [ngClass]="{'data-available': videoAvailable || audioAvailable, 
                            'excellent': signalState === 3, 'good': signalState === 2, 'ok': signalState === 1, 'bad': signalState === 0}"
                #publisherStatsPopover="bs-popover" [popover]="publisherDataTemplate" triggers="hover" placement="top" container="body" containerClass="stats-popover" [delay]='500'>
                <img src="assets/signal2.svg">
            </a>
            <i *ngIf="publisherMuted" class="mi mi-mic-off mic-muted mr-1"></i>
            <div class="publisher-name">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.PUBLISHER.YOU' | translate }}</div>
        </div>
        <div class="controls">
            <a  *ngIf="pipEnabled && !screenPublishing" class="control d-flex align-items-center" (click)="onTogglePictureInPicture()" title="Picture In Picture">
                <i class="mi mi-picture-in-picture-alt pip-control"></i>
            </a>
            <a class="control d-flex align-items-center ml-2" (click)="onToggleFullscreen()" *ngIf="!isMobileScreen">
                <i class="mi" [ngClass]="{'mi-fullscreen': !fullscreenState.fullscreen, 'mi-fullscreen-exit': fullscreenState.fullscreen}"></i>
            </a>
        </div>
    </div>

    <div *ngIf="screenPublishing && frame.type === 'publisher'" class="screen-share-container">
        <div class="screen-share text-center">
            <div>{{ 'APP.MAIN.ROOM.VIDEO_CHAT.PUBLISHER.SCREEN_SHARE_MESSAGE' | translate }}</div>
            <button class="btn btn-info mt-2" (click)="stopSharingScreen()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.PUBLISHER.STOP_SCREEN_SHARING' | translate }}</button>
        </div>
    </div>

    <div class="ar-dots" [ngClass]="{'mirror': cameraMirror, 'not-mirror': !cameraMirror}">
        <img [src]="'assets/arplus/'+arDot.shape+'-'+arDot.color+'.svg'" class="ar-dot" *ngFor="let arDot of arDots;" [ngStyle]="{left: arDot.left, top: arDot.top}">
    </div>

    <div class="ar-names" [class.small]="size === 'small' && !fullscreenState.fullscreen">
        <div class="ar-name" *ngFor="let arDot of arDots;" [style.color]="'#'+arDot.colorCode">{{ arDot.name }}</div>
    </div>

    <!--<div class="subtitle">{{ subtitle }}</div>-->
</div>

<ng-template #publisherDataTemplate>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.AUDIO_BITRATE' | translate }}: <img src="assets/north-24px.svg" width="10px" height="10px"> {{ (minStatsAudio === 10000 || minStatsAudio === 0) ? 'NA' : minStatsAudio }} <strong>kbps</strong></div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.AUDIO_PACKET_LOSS' | translate }}: <img src="assets/north-24px.svg" width="10px" height="10px"> {{ audioPacketLossRatio }}</div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.VIDEO_BITRATE' | translate }}: <img src="assets/north-24px.svg" width="10px" height="10px"> {{ (minStatsVideo === 10000 || minStatsVideo === 0) ? 'NA' : minStatsVideo }} <strong>kbps</strong></div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.VIDEO_PACKET_LOSS' | translate }}: <img src="assets/north-24px.svg" width="10px" height="10px"> {{ videoPacketLossRatio }} </div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.RESOLUTION' | translate }}: {{ screenWidthDynamic }}x{{ screenHeightDynamic }}</div>
</ng-template>