export const environment = {
  production: false,
  version: "7.2.0",
  name: "dev",
  design: {
    appName: "VSight",
    environmentName: "dev",
    addRemoteToName: true,
    wideLogo: false,
    logo: "assets/img/logo.png",
    logoStyle: {height:'48px','margin':'50px 0 50px'},
    wideLegalLogo: false,
    legalLogo: "assets/img/logo_legal.png",
    squareLogo: "assets/img/square_logo.png",
    showMsLogin: true,
    msLoginSquareLogo: false,
    msLoginText: "Sign in with Microsoft",
    msLoginTextDE: "Mit Microsoft anmelden",
    adamosLoginText: "Sign in with ADAMOS",
    adamosLoginTextDE: "Mit ADAMOS anmelden",
    loginColor: "#14389b",
    primaryColor: "#14389b",
    accentColor: "#00142E",
    linkColor: "#17A2B8",
    contactMail: "info@vsight.io",
    showLoggingInFromChina: true,
    showCompanyOnGuestAccess: true,
    showAppBadgesOnGuestAccess: true,
    arabicCollaborationTextFont: false,
    termsCountryAndDistrict: {
      country: "Germany",
      district: "Frankfurt"
    },
    showPoweredBy: false,
    hideRoomsMeetings: false,
    showHelpCenterLink: true,
    showFeedbackForm: true,
    showDrWidgets: false,
    showAnnounceKit: true,
    showPreferredResolution: false,
    useBaseUrlInsteadOfWindowOrigin: false,
    announceKitWidgetUrl: "https://announcekit.co/widgets/v2/3SNTnq",
    appStoreLink: "https://apps.apple.com/us/app/vsight-remote-ar-help/id1460130334",
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.hangaarlab.vsight.remoteapp",
    apkLink: "https://dev.vsight.io/downloads/remote/apk",
    legalAppName: "UAB, VSight",
    legalName: "app.vsight.io",
    legalMail: "legal@vsight.io",
    supportedBrowserList: ["Chrome", "Firefox", "Safari", "Microsoft Edge", "Samsung Internet for Android", "WeChat"],
    restrictedExtensions: []
  },
  translationLanguagesOverride: null,
  dbSource: "firebase",
  firebase: {
    apiKey: "AIzaSyDMmqISyiiWwgugYZ7XdJCSQL65W0unfbk",
    authDomain: "vsight-remote-angular-dev.firebaseapp.com",
    databaseURL: "https://vsight-remote-angular-dev-4d310.europe-west1.firebasedatabase.app/",
    projectId: "vsight-remote-angular-dev",
    storageBucket: "hangaarlab-remote",
    messagingSenderId: "848344207337"
  },
  endPoints: {
    base: "https://dev.vsight.io",
    adminBase: "https://admin-dev.vsight.io",
    workflowBase: "https://workflow-dev.vsight.io",
    login: "https://dev.vsight.io/api/login",
    changepsd: "https://dev.vsight.io/api/changepsd",
    changeemail: "https://dev.vsight.io/api/changeemail",
    joinroom: "https://dev.vsight.io/api/joinroom",
    endcall: "https://dev.vsight.io/api/endcall",
    upload: "https://dev.vsight.io/api/upload",
    uploadfiles: "https://dev.vsight.io/api/uploadfiles",
    togglearchive: "https://dev.vsight.io/api/togglearchive",
    translate: "https://dev.vsight.io/api/translate",
    changearchive: "https://dev.vsight.io/api/changearchive",
    joinwithlink: "https://dev.vsight.io/api/joinwithlink",
    gettestcredentials: "https://dev.vsight.io/api/gettestcredentials",
    trainingactions: "https://dev.vsight.io/api/trainingactions",
    exportsession: "https://dev.vsight.io/api/exportsession",
    downloadexport2: "https://dev.vsight.io/api/downloadexport2",
    callusers: "https://dev.vsight.io/api/callusers",
    remote_sso: "https://dev.vsight.io/api/remote_sso",
    createticket: "https://dev.vsight.io/api/createticket",
    attachticket: "https://dev.vsight.io/api/attachticket",
    updateticket: "https://dev.vsight.io/api/updateticket",
    deleteticket: "https://dev.vsight.io/api/deleteticket",
    accountagreement: "https://dev.vsight.io/api/accountagreement",
    schedulemeeting: "https://dev.vsight.io/api/schedulemeeting",
    cancelmeeting: "https://dev.vsight.io/api/cancelmeeting",
    getssolinkdata: "https://dev.vsight.io/api/getssolinkdata",
    invitefielduser: 'https://dev.vsight.io/api/fieldcode/invite',
    convert: "https://utils.vsight.io/convert",
    getUserSessions: "https://dev.vsight.io/apiV2/getUserSessions",
    getUserTickets:"https://dev.vsight.io/apiV2/getUserTickets",
    getTicketDetails:"https://dev.vsight.io/apiV2/getTicketDetails",
    switchToAdmin:"https://dev.vsight.io/apiV2/switchToAdmin",
    copyFileRoomToSession: "https://dev.vsight.io/api/copyFileRoomToSession",
    copyFileSessionToRoom: "https://dev.vsight.io/api/copyFileSessionToRoom",
    deleteRoomFile: "https://dev.vsight.io/api/deleteRoomFile",
    uploadRoomFiles: "https://dev.vsight.io/api/uploadRoomFiles",
    getworkflowlist:"https://dev.vsight.io/api/getworkflowlist",
    saveworkflowreport:"https://dev.vsight.io/api/saveworkflowreport",
    getDownloadLink:"https://dev.vsight.io/api/getDownloadLink",
    getExportPDF: "https://dev.vsight.io/api/generateSessionExportPdf",
    getUserContacts: "https://dev.vsight.io/api/getUserContacts",
    copyFileTicketToSession: "https://dev.vsight.io/api/copyFileTicketToSession",
    deleteTicketFile: "https://dev.vsight.io/api/deleteTicketFile",
    workflowRemoteIntegrationCall: "https://dev.vsight.io/api/workflowRemoteIntegrationCall",
    sessionrating: "https://dev.vsight.io/api/sessionrating",
  },
  cnEndPoints: {
    socketserver: "https://cn.vsight.io",
    getidtoken: "https://cn.vsight.io/api/getidtoken"
  },
  azureAdConfig: {
    msLoginUrl: "https://dev.ar-remote-assistance.com/${name}/saml/login"
  },
  adamosLoginConfig: {
    adamosLoginUrl: "https://dev.ar-remote-assistance.com/oauth/login"
  },
  feedbackForm: {
    region: "na1",
    portalId: "3929010",
    formId: "c8d0dbba-b752-4d30-b45e-1f831ae74101"
  },
  appFeatureMinVersions: {
    ios: {
      artext: "3.3.0"
    },
    android: {
      artext: "4.8.8"
    }
  }
};
