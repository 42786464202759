<nav class="navbar navbar-light p-0">
    <div class="center-bar container-fluid">
        <div class="session-time">{{ sessionTime }}</div>

        <div #micCamTutorial="tutorial" tutorial="micCamOff" (change)="$event ? micCamPopover.show() : micCamPopover.hide()"
                #micCamPopover="bs-popover" [popover]="micCamTmp" triggers="" placement="top" containerClass="tutorial-popover" class="controlbar-wrapper px-2">
            <div class="mic-cam-btn-group btn-group" dropdown #micDropdown="bs-dropdown" [dropup]="true">
                <a class="controlbar-btn text-center" (click)="onToggleAudio()" [class.disabled]="audioUnavailable || trainingDisable">
                    <i class="mi" [ngClass]="{ 'mi-mic': pAudio, 'mi-mic-off': !pAudio }"></i>
                    <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.MIC_OFF' | translate }}</div>
                </a>
                <a class="mic-cam-dropdown-toggle dropdown-toggle dropdown-toggle-split" id="mic-button-dropup" dropdownToggle aria-controls="mic-dropdown-dropup">
                    <i class="mi" [ngClass]="{ 'mi-arrow-drop-up': !micDropdown.isOpen, 'mi-arrow-drop-down': micDropdown.isOpen }"></i>
                </a>
                <ul id="mic-dropdown-dropup" *dropdownMenu class="mic-cam-dropdown-menu dropdown-menu py-1" role="menu" aria-labelledby="mic-button-dropup">
                    <li *ngFor="let a of audioDevices" role="menuitem"><a class="dropdown-item" [class.selected]="a.deviceId === selectedAudioDeviceId" (click)="onAudioDeviceSelected(a)">{{ a.label }}</a></li>
                </ul>
            </div>
            <div class="mic-cam-btn-group btn-group" dropdown #camDropdown="bs-dropdown" [dropup]="true">
                <a class="controlbar-btn text-center ml-2" (click)="onToggleVideo()" [class.disabled]="videoUnavailable || pScreen || trainingDisable">
                    <i class="mi" [ngClass]="{ 'mi-videocam': pVideo, 'mi-videocam-off': !pVideo }"></i>
                    <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.CAM_OFF' | translate }}</div>
                </a>
                <a class="mic-cam-dropdown-toggle dropdown-toggle dropdown-toggle-split" id="cam-button-dropup" dropdownToggle aria-controls="cam-dropdown-dropup">
                    <i class="mi" [ngClass]="{ 'mi-arrow-drop-up': !camDropdown.isOpen, 'mi-arrow-drop-down': camDropdown.isOpen }"></i>
                </a>
                <ul id="cam-dropdown-dropup" *dropdownMenu class="mic-cam-dropdown-menu dropdown-menu py-1" role="menu" aria-labelledby="cam-button-dropup">
                    <li *ngFor="let v of videoDevices" role="menuitem"><a class="dropdown-item" [class.selected]="v.deviceId === selectedVideoDeviceId" (click)="onVideoDeviceSelected(v)">{{ v.label }}</a></li>
                </ul>
            </div>
        </div>
        <div #endCallTutorial="tutorial" tutorial="endCall" (change)="$event ? endCallPopover.show() : endCallPopover.hide()"
                #endCallPopover="bs-popover" [popover]="endCallTmp" triggers="" placement="top" containerClass="tutorial-popover" class="controlbar-wrapper px-2">
            
            <div class="btn-group" dropdown #endCallDropdown="bs-dropdown" triggers="click" [dropup]="true" [isDisabled]="!(showEndMeetingForAll || meetingRoom || personalRoom)" >
                <a class="controlbar-btn text-center" id="call-dropup-btn" dropdownToggle aria-controls="call-dropup-menu" (click)=" (((showEndMeetingForAll || meetingRoom) && expertUser) || personalRoom) ? null : onEndCall(false);">
                    <i class="mi mi-call-end text-danger" [ngClass]="{'leave-button': mandatoryTicket}"></i><div class="d-none d-sm-block" [ngClass]="{'leave-button': mandatoryTicket}">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.LEAVE_CALL' | translate }}</div>
                </a>
                <ul id="call-dropup-menu" *dropdownMenu class="dropdown-menu endcall-dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="call-dropup-btn" [ngClass]="{'d-none' : !((showEndMeetingForAll || meetingRoom) && expertUser) && !personalRoom}">
                    <li role="menuitem">
                        <div class="dropdown-item px-3 py-1">
                            <a class="btn btn-sm btn-danger" style="cursor: pointer;" (click)="onEndCall(true)">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.END_MEETING_FOR_ALL' | translate }}</a>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="dropdown-item px-3 py-1">
                            <a class="btn btn-sm btn-secondary" style="cursor: pointer;" (click)="onEndCall(false)">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.LEAVE_MEETING' | translate  }}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="right-bar">
        <div class="right-bar-container" [ngClass]="{'always-open': rightControlsAlwaysOpen, 'sidebar-open': sidebarOpen}">
            <a class="right-bar-close-btn pl-2" (click)="rightControlsAlwaysOpen = !rightControlsAlwaysOpen"><i class="mi mi-close"></i></a>

            <div class="controlbar-wrapper" *ngIf="roomUserStatus && roomUserStatus.training_room && roomUserStatus.user_status !== 'master'" [ngSwitch]="roomUserStatus.user_status">
                <a *ngSwitchCase="'waiting'" class="controlbar-btn text-center" (click)="onRaiseHand()" [class.disabled]="!sessionActive || handRaiseInProgress">
                    <img src="assets/raise-hand-black.svg">
                    <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.RAISE_HAND' | translate }}</div>
                </a>
                <!--<a *ngSwitchCase="'publishing'" class="controlbar-btn text-center" (click)="onUnpublishSelf()" [class.disabled]="false">
                    <i class="mi mi-block text-danger"></i>
                    <div class="d-none d-sm-block text-danger">Slience</div>
                </a>-->
                <a *ngSwitchCase="'hand-raised'" class="controlbar-btn text-center" (click)="onLowerHand()" [class.disabled]="!sessionActive || handRaiseInProgress">
                    <img src="assets/raise-hand.svg">
                    <div class="d-none d-sm-block" style="color: #4582b3;">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.LOWER_HAND' | translate }}</div>
                </a>
            </div>

            <div #recordTutorial="tutorial" tutorial="record" (change)="$event ? recordPopover.show() : recordPopover.hide()"
                    #recordPopover="bs-popover" [popover]="recordTmp" triggers="" placement="top" containerClass="tutorial-popover" class="controlbar-wrapper px-2"
                    [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.archiving" [tooltipDelay]="200">
                <div class="btn-group" dropdown #archiveDropup="bs-dropdown" [dropup]="true">
                    <a *ngIf="!archiving" class="controlbar-btn text-center" id="archive-dropup" (click)="onStartArchive($event)" [class.cancel-archive]="archivePermNeeded && !this.roomAutoArchive && archivePermRequested && !this.archivePermGranted"
                            [class.disabled]="!(features.archiving && userArchiveAllowed && !roomPeerToPeer) || archiveLoading || trainingDisable || this.roomAutoArchive || this.archivePermGranted">
                        <i class="mi mi-radio-button-checked"></i>
                        <div class="d-none d-sm-block">{{ ((archivePermNeeded && !this.roomAutoArchive && archivePermRequested) ? 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.CANCEL' : 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.RECORD') | translate }}</div>
                    </a>
                    <ul id="archive-dropup-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right p-0" role="menu">
                        <li role="menuitem"><a class="dropdown-item py-2" style="font-size: 14px;" (click)="onAskRecordPermission();">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ASK_ARCHIVE_PERMISSION' | translate }}</a></li>
                    </ul>
                </div>
                <ng-container *ngIf="archiving">
                    <a *ngIf="roomAutoArchive" class="controlbar-btn text-center text-danger disabled">
                        <i class="mi mi-radio-button-checked"></i>
                        <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.AUTO_RECORD' | translate }}</div>
                    </a>
                    <a *ngIf="!roomAutoArchive" class="controlbar-btn text-center text-danger" [class.disabled]="!userArchiveAllowed || archiveLoading || trainingDisable" (click)="onStopArchive()">
                        <i class="mi mi-radio-button-checked"></i>
                        <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.STOP_RECORD' | translate }}</div>
                    </a>
                </ng-container>
            </div>
            <div #screenShareTutorial="tutorial" tutorial="screenShare" (change)="$event ? screenSharePopover.show() : screenSharePopover.hide()"
                    #screenSharePopover="bs-popover" [popover]="screenShareTmp" triggers="" placement="top" containerClass="tutorial-popover" class="controlbar-wrapper px-2"
                    [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.screensharing" [tooltipDelay]="200">
                <a class="controlbar-btn text-center" [ngClass]="{ 'screen-publishing': pScreen }" (click)="onToggleScreenSharing()" [class.disabled]="!features.screensharing || pScreenDisabled || rtranslationStarted">
                    <i class="mi mi-screen-share"></i>
                    <div class="d-none d-sm-block" *ngIf="!pScreen">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.SHARE_SCREEN' | translate }}</div>
                    <div class="d-none d-sm-block" *ngIf="pScreen">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.STOP_SHARE' | translate }}</div>
                </a>
            </div>
            <div *ngIf="rTranslationAvailable" class="controlbar-wrapper px-2" [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="rTranslationAvailable" [tooltipDelay]="200">
                <a class="controlbar-btn text-center" [ngClass]="{ 'screen-publishing': rtranslationStarted }" (click)="onToggleTranslate()" [class.disabled]="!rTranslationAvailable || rtranslationDisabled || pScreen || roomPeerToPeer">
                    <img *ngIf="!rtranslationStarted" src="assets/transcribe-icon.svg">
                    <img *ngIf="rtranslationStarted" src="assets/transcribe-icon-2.svg">
                    <div class="d-none d-sm-block" *ngIf="!rtranslationStarted">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.RTRANSLATION' | translate }}</div>
                    <div class="d-none d-sm-block" *ngIf="rtranslationStarted">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.TRANSLATING' | translate }}</div>
                </a>
            </div>

            <div #reactionTutorial="tutorial" tutorial="reaction" (change)="$event ? reactionPopover.show() : reactionPopover.hide()"
                    #reactionPopover="bs-popover" [popover]="reactionTmp" triggers="" placement="right" containerClass="tutorial-popover" class="controlbar-wrapper px-2"
                    [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.reactions" [tooltipDelay]="200">
                <div class="btn-group" dropdown #dropdown="bs-dropdown" triggers="click" [dropup]="true" [isDisabled]="!(features.reactions && sessionActive) || trainingDisable">
                    <a class="controlbar-btn text-center" id="button-dropup" dropdownToggle aria-controls="dropdown-dropup" (click)="onReactionClick()" [class.disabled]="!(features.reactions && sessionActive) || trainingDisable">
                        <i class="mi mi-thumb-up"></i><div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.REACTION' | translate }}</div>
                    </a>
                    <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu reaction-dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-dropup">
                        <li role="menuitem"><a class="dropdown-item" (click)="onSendReaction('thumb-up')"><i class="mi mi-thumb-up"></i></a></li>
                        <li role="menuitem"><a class="dropdown-item" (click)="onSendReaction('thumb-down')"><i class="mi mi-thumb-down"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="open-right-menu">
            <a class="controlbar-btn d-flex align-items-center px-2" #rightMenuOpenTutorial="tutorial" tutorial="rightMenuOpen"
                    (click)="(rightControlsAlwaysOpen = !rightControlsAlwaysOpen); rightMenuOpenTutorial.next({record: true})">
                <i class="mi mi-more-horiz"></i>
            </a>
        </div>
    </div>
</nav>

<ng-template #micCamTmp>
    <div>{{ 'APP.TUTORIALS.MIC_CAMERA' | translate }}</div>
    <a (click)="micCamTutorial.next({endCall: true})">{{ 'APP.TUTORIALS.NEXT' | translate }}</a>
</ng-template>

<ng-template #endCallTmp>
    <div>{{ 'APP.TUTORIALS.END_CALL' | translate }}</div>
    <a (click)="endCallTutorial.next({record: true, rightMenuOpen: true})">{{ 'APP.TUTORIALS.NEXT' | translate }}</a>
</ng-template>

<ng-template #recordTmp>
    <div>{{ 'APP.TUTORIALS.RECORD' | translate }}</div>
    <a (click)="recordTutorial.next({screenShare: true, rightMenuOpen: true})">{{ 'APP.TUTORIALS.NEXT' | translate }}</a>
</ng-template>

<ng-template #screenShareTmp>
    <div>{{ 'APP.TUTORIALS.SCREEN_SHARING' | translate }}</div>
    <a (click)="screenShareTutorial.next({reaction: true, rightMenuOpen: true})">{{ 'APP.TUTORIALS.NEXT' | translate }}</a>
</ng-template>

<ng-template #reactionTmp>
    <div>{{ 'APP.TUTORIALS.REACTION' | translate }}</div>
    <a (click)="onReactionNext(reactionTutorial)">{{ 'APP.TUTORIALS.NEXT' | translate }}</a>
</ng-template>

<ng-template #endCallTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <!--<h5 class="modal-title" *ngIf="!dataModel.endForAll && !(showEndMeetingForAll || meetingRoom)">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.TITLE_LEAVE' | translate }}</h5>-->
                <h5 class="modal-title" *ngIf="!dataModel.endForAll">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.TITLE_LEAVE_MEETING' | translate }}</h5>
                <!--<h5 class="modal-title" *ngIf="dataModel.endForAll && !(showEndMeetingForAll || meetingRoom)">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.TITLE_END_ALL' | translate }}</h5>-->
                <h5 class="modal-title" *ngIf="dataModel.endForAll">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.TITLE_END_ALL_MEETING' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <!--<p class="mx-3" *ngIf="!dataModel.endForAll && !(showEndMeetingForAll || meetingRoom)">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.MESSAGE_LEAVE' | translate }}</p>-->
                <p class="mx-3" *ngIf="!dataModel.endForAll">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.MESSAGE_LEAVE_MEETING' | translate }}</p>
                <!--<p class="mx-3" *ngIf="dataModel.endForAll && !(showEndMeetingForAll || meetingRoom)">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.MESSAGE_END_ALL' | translate }}</p>-->
                <p class="mx-3" *ngIf="dataModel.endForAll">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.MESSAGE_END_ALL_MEETING' | translate }}</p>
                <div class="custom-control custom-checkbox d-flex align-items-center mx-3 mt-2" *ngIf="invalidateMeetingLink && dataModel.endForAll && dataModel.meetingRoom">
                    <input type="checkbox" class="custom-control-input" [formControl]="removeMeetingLink" id="removeMeeting">
                    <label class="custom-control-label" for="removeMeeting">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.REMOVE_MEETING_LINK' | translate }}</label>
                </div>
            </div>
    
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.CANCEL' | translate }}</button>

                <button *ngIf="!dataModel.personalRoom && !(dataModel.showEndMeetingForAll || dataModel.meetingRoom)" class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.LEAVE' | translate }}</button>
                <button *ngIf="(dataModel.personalRoom && !dataModel.endForAll) || ((dataModel.showEndMeetingForAll || dataModel.meetingRoom) && !dataModel.endForAll)" class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.LEAVE' | translate }}</button>
                <button *ngIf="(dataModel.personalRoom && dataModel.endForAll) || ((dataModel.showEndMeetingForAll || dataModel.meetingRoom) && dataModel.endForAll)" class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.END' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #installExtensionTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.EXTENSION_MODAL_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.EXTENSION_MODAL_MESSAGE' | translate }}</p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                    {{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.EXTENSION_MODAL_NO' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                    {{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.EXTENSION_MODAL_YES' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #startRTranslationTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.R_TRANSLATION_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <div class="py-3 px-4">
                    <div class="mb-2">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.R_TRANSLATION_MODAL.TRANSLATE_FROM' | translate }}</div>
                    <form #translationForm="ngForm">
                        <div class="form-group m-0">
                            <ng-select [items]="azureLanguages" [clearable]="false" name="languages" bindLabel="displayName" bindValue="languageCode" placeholder="" [(ngModel)]="selectedLanguageCode" (change)="onLangChange($event)"></ng-select>
                        </div>
                    </form>
                </div>
                <div class="py-3 px-4">
                    <div class="mb-2">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.R_TRANSLATION_MODAL.TRANSLATE_TO' | translate }}</div>
                    <form #translationForm2="ngForm">
                        <div class="form-group m-0">
                            <ng-select [items]="languages" [clearable]="false" name="languages" bindLabel="displayName" bindValue="languageCode" placeholder="" [(ngModel)]="selectedLanguageCode2" (change)="onLangChange2($event)"></ng-select>
                        </div>
                    </form>
                </div>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.R_TRANSLATION_MODAL.CANCEL' | translate }}</button>
                <button class="btn btn-sm btn-info px-4" (click)="callbacks.yes()" [disabled]="!(currentPublisher && selectedLanguageCode && selectedLanguageCode2)">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.R_TRANSLATION_MODAL.START' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>
