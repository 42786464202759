<div class="lobby-wrapper">
    <div class="lobby-content" [ngClass]="{ 'sidebar-open': sidebarOpen }">
        <router-outlet></router-outlet>
        <template #detailContainer></template>
        <template #detailContainer2></template>
        <div class="lobby-overlay" (click)="onSidebarLinkClicked()"></div>
    </div>
    <div class="lobby-sidebar" [ngClass]="{ 'sidebar-open': sidebarOpen }">
        <div class="sidebar-wrapper">
            <div class="sidebar-content" style="overflow-y: auto;">
                <ul class="sidebar-menu">
                    <li *ngIf="!('design' | env).hideRoomsMeetings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-dashboard blue-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.LOBBY' | translate }}</span>
                    </a></li>
                    <li *ngIf="!('design' | env).hideRoomsMeetings" routerLinkActive="active"><a routerLink="/rooms" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-contact-phone green-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.ROOMS' | translate }}</span>
                    </a></li>
                    <li *ngIf="!('design' | env).hideRoomsMeetings" routerLinkActive="active"><a routerLink="/meetings" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-people dark-green-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.MEETINGS' | translate }}</span>
                    </a></li>
                    <li *ngIf="contactsAvailable" routerLinkActive="active"><a routerLink="/contacts" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-person red-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.CONTACTS' | translate }}</span>
                    </a></li>
                    <li routerLinkActive="active"><a routerLink="/sessions" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-people yellow-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.SESSIONS' | translate }}</span>
                    </a></li>
                    <li *ngIf="ticketsAvailable" routerLinkActive="active"><a routerLink="/tickets" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-style dark-blue-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.TICKETS' | translate }}</span>
                    </a></li>
                    <li routerLinkActive="active"><a routerLink="/precall" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-trending-up blue-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.PRECALL_TEST' | translate }}</span>
                    </a></li>
                    <li routerLinkActive="active"><a routerLink="/converter/upload" (click)="onSidebarLinkClicked()">
                        <i class="mi mi-autorenew green-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LOBBY.CONVERTER.TITLE' | translate }}</span>
                        <span class="beta-tag text-white py-1 px-2 my-auto ml-auto mr-2">BETA</span>
                    </a></li>
                    <li *ngIf="('design' | env).showHelpCenterLink"><a href="http://help.vsight.io/" target="_blank" rel="noopener" (click)="onSidebarLinkClicked();">
                        <i class="mi mi-help-outline grey-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.HELP_CENTER' | translate }}</span>
                    </a></li>
                    <li class="down-sidebar-item" *ngIf="('design' | env).showFeedbackForm" routerLinkActive="active"><a routerLink="/feedback" (click)="onSidebarLinkClicked();">
                        <i class="mi mi-textsms grey-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.FEEDBACK' | translate }}</span>
                    </a></li>
                </ul>
            </div>

            <div class="down-sidebar-content" [ngStyle]="{'margin-top': !('design' | env).showFeedbackForm ? '-6px' : '-43px'}">
                <ul class="sidebar-menu">
                    <li *ngIf="('design' | env).showFeedbackForm" routerLinkActive="active"><a routerLink="/feedback" (click)="onSidebarLinkClicked();">
                        <i class="mi mi-textsms grey-color text-dark mr-3"></i><span>{{ 'APP.MAIN.LEFT_BAR.FEEDBACK' | translate }}</span>
                    </a></li>
                </ul>
            </div>
            <div class="sidebar-toggle-container">
                <div class="app-version">v{{ appVersion }}</div>
                <a class="sidebar-toggle-link" (click)="sidebarOpen = !sidebarOpen">
                    <i class="mi text-secondary" [ngClass]="{'mi-keyboard-arrow-left': sidebarOpen, 'mi-keyboard-arrow-right': !sidebarOpen}"></i>
                </a>
            </div>
        </div>
    </div>
</div>

<ng-template #idleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.IDLE_MODAL_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.LOBBY.IDLE_MODAL_MESSAGE' | translate }}</p>
                <p class="mx-3 mt-2">{{ dataModel.countMessage }}</p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.ok()">
                    {{ 'APP.MAIN.LOBBY.IDLE_MODAL_OK' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #logoutTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                  <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p *ngIf="dataModel.inRoom">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_MESSAGE_IN_ROOM' | translate }}</p>
                <p *ngIf="dataModel.inRoom" class="mt-2" style="color: red">{{ 'APP.MAIN.NAVBAR.CAUTION' | translate }}</p>
                <p *ngIf="!dataModel.inRoom">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_MESSAGE' | translate }}</p>
            </div>
  
            <div class="modal-footer">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                    {{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_NO' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                    {{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_YES' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #profileTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.PROFILE_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-0">
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="pl-4" scope="row">{{ 'APP.MAIN.NAVBAR.PROFILE_NAME' | translate }}</th>
                            <td>{{ dataModel.user.name }}</td>
                        </tr>
                        <tr>
                            <th class="pl-4" scope="row">{{ 'APP.MAIN.NAVBAR.PROFILE_PASSWORD' | translate }}</th>
                            <td><span class="mr-2">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
                            (<a class="text-info" style="cursor: pointer;" (click)="callbacks.changePassword()">{{ 'APP.MAIN.NAVBAR.PROFILE_CHANGE_LINK' | translate }}</a>)
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">
                    {{ 'APP.MAIN.NAVBAR.PROFILE_CLOSE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #changePassTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-2">
                <form [formGroup]="dataModel.form" autocomplete="off" class="change-pass-form form-inline" (ngSubmit)="callbacks.change()">
                    <input autocomplete="false" name="hidden" type="text" style="display:none;"> <!--for disable autocomplete-->
                    <div class="form-group col-12">
                        <label class="col-5" for="oldPassword">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_OLD_PASS' | translate }}</label>
                        <input
                            formControlName="oldPassword"
                            [formGroup]="dataModel.form"
                            class="col-6 rounded-0 field form-control"
                            type="password"
                        />
                        <div class="col-5"></div>
                        <small class="col-7" [hidden]="!dataModel.form.controls.oldPassword.touched || !dataModel.form.controls.oldPassword.errors?.required">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_REQUIRED' | translate }}</small>
                        <small class="col-7" [hidden]="!dataModel.form.controls.oldPassword.touched || !dataModel.form.controls.oldPassword.errors?.minlength">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_SHORT' | translate }}</small>
                    </div>
                    <div class="form-group col-12">
                        <label class="col-5" for="newPassword">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_NEW_PASS' | translate }}</label>
                        <input
                            formControlName="newPassword"
                            [formGroup]="dataModel.form"
                            class="col-6 rounded-0 field form-control"
                            type="password"
                        />
                        <div class="col-5"></div>
                        <small class="col-7" [hidden]="!dataModel.form.controls.newPassword.touched || !dataModel.form.controls.newPassword.errors?.required">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_REQUIRED' | translate }}</small>
                        <small class="col-7" [hidden]="!dataModel.form.controls.newPassword.touched || !dataModel.form.controls.newPassword.errors?.minlength">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_SHORT' | translate }}</small>
                    </div>
                    <div class="form-group col-12">
                        <label class="col-5" for="repeatPassword">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_RPT_PASS' | translate }}</label>
                        <input
                            formControlName="repeatPassword"
                            [formGroup]="dataModel.form"
                            class="col-6 rounded-0 field form-control"
                            type="password"
                        />
                        <div class="col-5"></div>
                        <small class="col-7" [hidden]="!dataModel.form.controls.repeatPassword.touched || !dataModel.form.controls.repeatPassword.errors?.MatchPassword">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_NOT_MATCH' | translate }}</small>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">
                    {{ 'APP.MAIN.NAVBAR.CHANGE_PASS_CLOSE_BTN' | translate }}
                </button>
                <button class="btn btn-sm btn-info px-4" [disabled]="dataModel.form.status !== 'VALID'" (click)="callbacks.change()">
                    {{ 'APP.MAIN.NAVBAR.CHANGE_PASS_CHANGE_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #agreementModel let-callbacks="callbacks">
    <div class="modal-dialog modal-dialog-wide" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ 'APP.MAIN.LOBBY.AGREEMENT_MODAL.TITLE' | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <p class="agreementModel">
                    Bu dokumanı onaylayarak 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) 5. Maddesi kapsamında Şirketimize iletmiş olduğunuz kişisel verilerinizin, bilgi vermek ve sizlerle iletişime geçmek, görüntü toplamak, istatistiki bilgi toplamak ve bu işlemlerin yapılması amacıyla verileri yurtiçi ve yurtdışında yerleşik üçüncü kişilere aktarmak maksadıyla Şirketimiz tarafından işlenmesine açıkça rıza göstermektesiniz. Kişisel verileriniz kanuni düzenlemelere uygun olarak ticari teamüllerde öngörülen sürelerde işlenecektir. Kanun kapsamında Veri Sorumlumuz; Kanun’daki tüm sorumluluk ve yükümlülüklerine uymayı kabul etmektedir.
                    <br/>
                    Kişisel Verilerinizin, Veri Sorumlusu şahsın/Şirketimizin belirleyeceği yurtiçi veya yurtdışında bulunan 3. kişilere işbu verilerin saklanması, depolanması ve açık rıza gösterilen amaçlarla işlenmesi için aktarılmasına açıkça onay vermektesiniz.
                    <br/>
                    Kanuni haklarınız kapsamında; dilediğiniz zaman Veri Sorumlusu Şirketimize başvurarak tarafınıza ait kişisel verilerin işlenip işlenmediğini öğrenme, işlenen kişisel verileri varsa bunlara ilişkin bilgi talep etme, kişisel verilerin işlenme amacını ve bu verilerin amaca uygun kullanılıp kullanılmadığını öğrenme, kişisel verilerinin aktarıldığı üçüncü kişileri bilme, kişisel verilerindeki hataların düzeltilmesini ve eğer aktarım yapılmışsa ilgili üçüncü kişiden bu düzeltmenin istenmesini talep etme, kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde bu verilerin silinmesini, yok edilmesini ya da anonim hale getirilmesini isteme ve eğer aktarım yapılmışsa bu talebin aktarılan üçüncü kişiye iletilmesini isteme, işlenen verilerin neticesinde kişi ile ilintili olumsuz bir sonuç çıkmasına itiraz etme hakkınız mevcuttur. 
                    <br/>
                    Şirketimiz tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuat hükümleri kapsamında yapılmış olan işbu bilgilendirme ve aydınlatma sonrasında kişisel verileriniz aşağıdaki açık muvafakatiniz ile toplanacaktır:
                    <br/>
                    Ford otomotiv Sanayi A.Ş. tarafından açıklanan 6698 sayılı Kişisel Verilerin Korunması Kanunu’na ilişkin aydınlatma metninin tamamını okudum, anladım ve Ford otomotiv Sanayi A.Ş. Verilerimi yukarıda belirtilen amaçlar çerçevesinde işlemesi konusunda bilgilendirildim. Bu kapsamda Kişisel Verilerimin Aydınlatma Metni’nde belirtilen hususlar dahilinde paylaşılmasına konu hakkında tereddüde yer vermeyecek şekilde aydınlatılmış ve bilgi sahibi olarak, açık rızamla onay veriyorum.
                </p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.reject()">{{ 'APP.MAIN.LOBBY.AGREEMENT_MODAL.REJECTION_BUTTON' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.accept()">{{ 'APP.MAIN.LOBBY.AGREEMENT_MODAL.AGREEMENT_BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #checkAgreementModel let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                {{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.STATEMENT' | translate }}
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.CANCEL_BUTTON' | translate }}</button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.proceed()">{{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.PROCEED_BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #scheduleMeetingTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="schedule-meeting-modal-dialog modal-dialog modal-dialog-wide" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="form-inside modal-body px-4" style="overflow-y: auto;">
                <form #scheduleForm="ngForm" class="ticket-form" (ngSubmit)="callbacks.schedule(scheduleForm);">
                    <div class="form-row mb-2">
                        <div class="col-1 d-flex justify-content-center align-items-center">
                            <i class="mi mi-title text-muted" style="font-size: 22px;"></i>
                        </div>
                        <div class="form-group col-11 mb-0">
                            <input #title="ngModel" class="form-control" style="font-size: 14px;" type="text" name="title" placeholder="{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.MEETING_TITLE' | translate }}" 
                                [ngClass]="{'is-invalid':title.errors && title.touched}" [(ngModel)]="dataModel.title" maxlength="60" required>
                            <small class="invalid-feedback mx-2" [hidden]="!title.errors?.required">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.MEETING_TITLE_REQUIRED' | translate }}</small>
                        </div>
                    </div>
                    <div class="form-row mb-2">
                        <div class="col-1 d-flex justify-content-center align-items-center">
                            <i class="mi mi-people text-muted" style="font-size: 22px;"></i>
                        </div>
                        <div class="form-group col-11 mb-0" style="font-size: 16px;">
                            <ng-select [items]="dataModel.users" [clearable]="false" [searchable]="true" name="users" bindLabel="name" bindValue="id"
                                placeholder="{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.ENTER_USERS' | translate }}" [multiple]="true" [clearSearchOnAdd] = "true" [closeOnSelect]="false" (change)="callbacks.userListChanged($event)"></ng-select>    
                        </div>
                    </div>
                    <div class="form-row mb-3">
                        <div class="col-1 d-flex justify-content-center align-items-center">
                            <i class="mi mi-schedule text-muted" style="font-size: 22px;"></i>
                        </div>
                        <div class="col-11">
                            <div class="form-row mb-1">
                                <div class="form-group datepicker-form-group d-flex align-items-center col-6 mb-0">
                                    <input #startDate="bsDatepicker" name="startDate" class="form-control" id="form-control" bsDatepicker placeholder="{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.START_DATE' | translate }}" style="font-size: 13px;"
                                        [(ngModel)]="dataModel.start.date" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;" (ngModelChange)="callbacks.startDateChanged($event)">
                                </div>
                                <div class="form-group col-6 mb-0">
                                    <ng-select [items]="dataModel.startSlots" [clearable]="false" [searchable]="false" name="startTime" bindLabel="label" bindValue="time"
                                        placeholder="" [(ngModel)]="dataModel.start.time" (change)="callbacks.startTimeChanged($event)"></ng-select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group datepicker-form-group d-flex align-items-center col-6 mb-0">
                                    <input #endDate="bsDatepicker" [minDate]="dataModel.start.date" name="endDate" id="form-control" class="form-control" bsDatepicker placeholder="{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.END_DATE' | translate }}" style="font-size: 13px;"
                                        [(ngModel)]="dataModel.end.date" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;" (ngModelChange)="callbacks.endDateChanged($event)">
                                </div>
                                <div class="form-group col-6 mb-0">
                                    <ng-select [items]="dataModel.endSlots" [clearable]="false" [searchable]="false" name="endTime" bindLabel="label" bindValue="time"
                                        placeholder="" [(ngModel)]="dataModel.end.time" (change)="callbacks.endTimeChanged($event)"></ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-3">
                        <div class="col-1 d-flex justify-content-center align-items-center">
                            <i class="mi mi-timer text-muted" style="font-size: 22px;"></i>
                        </div>
                        <div class="col-11 d-flex align-items-center">{{ dataModel.duration }}</div>
                    </div>
                    <div class="form-row">
                        <div class="col-1 d-flex justify-content-center align-items-center">
                        </div>
                        <div class="col-11 d-flex align-items-center" style="font-size: 12px; font-weight: bold; color: #8B8A8A">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SEND_INVITATIONS' | translate }}</div>
                    </div>
                    <div class="form-row mb-2">
                        <div class="col-1 d-flex justify-content-center align-items-center"><i class="mi mi-email text-muted" style="font-size: 22px;"></i></div>
                        <tag-input class="col-11" #tagInput name="emailRecipients" style="font-size: 14px;" [(ngModel)]="dataModel.emailRecipients" placeholder="{{ 'APP.MAIN.EMAIL_RECIPIENTS.PLACEHOLDER' | translate }}"
                            secondaryPlaceholder="{{ 'APP.MAIN.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER' | translate }}" theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError"
                            [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>
                    </div>
                    <div class="form-row mb-1">
                        <div class="col-1 d-flex justify-content-center align-items-center"></div>
                        <div class="col-11 d-flex align-items-center" style="font-size: 12px; font-weight: bold; color: #8B8A8A">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.INVITATION_EMAIL_LANG' | translate }}</div>
                    </div>
                    <div class="form-row mb-3">
                        <div class="col-1 d-flex justify-content-center align-items-center"><i class="mi mi-language text-muted" style="font-size: 22px;"></i></div>
                        <div class="col-11 d-flex">
                            <div class="language-dropdown" dropdown [dropup]="true" #dropdown="bs-dropdown">
                                <button id="language-toggle" type="button" class="language-link d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                                    <!--<i class="mi mi-language ml-1" style="font-size: 20px;"></i>-->
                                    <span class="ml-2">{{ dataModel.emailLang | uppercase }}</span>
                                    <i class="mi mi-keyboard-arrow-down ml-2"></i>
                                </button>
                                <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                                    <ng-container *ngFor="let lang of dataModel.emailLangList">
                                        <li class="language-item" role="menuitem" *ngIf="dataModel.emailLang !== lang">
                                            <a class="dropdown-item language-link px-2" (click)="callbacks.onLanguageSelected(lang)">{{ lang | uppercase }}</a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-2">
                        <div class="col-1 d-flex justify-content-center align-items-center">
                        </div>
                        <div class="col-11 d-flex align-items-center" style="font-size: 12px; font-weight: bold; color: #8B8A8A">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SHARE_AFTER' | translate }}</div>
                    </div>
                    <div class="form-row" *ngIf="accountFullHdAllowed">
                        <div class="col-1 d-flex justify-content-center align-items-center">
                            <!--<i class="mi mi-hd text-muted" style="font-size: 22px;"></i>-->
                        </div>
                        <div class="form-group mb-0 col-11">
                            <div class="custom-control custom-checkbox d-flex align-items-center w-100">
                                <input type="checkbox" class="custom-control-input" id="fullhd" name="fullhd" [(ngModel)]="dataModel.fullhd" [disabled]="alwaysMobileFullHd">
                                <label class="custom-control-label" style="font-size: 12px !important; font-weight: bold; color: #8B8A8A" for="fullhd">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.FULL_HD' | translate }}</label>
                            </div>
                            <label *ngIf="alwaysMobileFullHd" for="fullhd" class="text-danger" style="font-size: 12px !important;">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.ALWAYS_FULL_HD' | translate }}</label>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.CLOSE' | translate }}</button>
                <button class="btn btn-sm btn-info px-4" [disabled]="!scheduleForm.valid" *ngIf="dataModel.emailRecipients.length>0" (click)="callbacks.schedule(scheduleForm)">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SCHEDULE_SEND' | translate }}</button>
                <button class="btn btn-sm btn-info px-4" [disabled]="!scheduleForm.valid" *ngIf="dataModel.emailRecipients.length===0" (click)="callbacks.schedule(scheduleForm)">{{ 'APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.SCHEDULE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cancelMeetingTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ (dataModel.previous ? 'APP.MAIN.LOBBY.CANCEL_MEETING_MODAL.REMOVE_TITLE' : 'APP.MAIN.LOBBY.CANCEL_MEETING_MODAL.CANCEL_TITLE') | translate }}</h5>
            </div>
            <div class="modal-body px-4">
                <div class="mb-2">{{ (dataModel.previous ? 'APP.MAIN.LOBBY.CANCEL_MEETING_MODAL.REMOVE_MESSAGE' : 'APP.MAIN.LOBBY.CANCEL_MEETING_MODAL.CANCEL_MESSAGE') | translate }}</div>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.CANCEL_MEETING_MODAL.CLOSE' | translate }}</button>
                <button class="btn btn-sm px-4" [ngClass]="{'btn-info': dataModel.previous, 'btn-danger': !dataModel.previous}" (click)="callbacks.proceed()">{{ 'APP.MAIN.LOBBY.CANCEL_MEETING_MODAL.PROCEED' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>