import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { timer ,  combineLatest, fromEvent, Subscription } from 'rxjs';

import { AuthService } from '@services/core/auth.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { OpentokService } from '@services/core/opentok.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { MultilanguageService } from '@services/support/multilanguage.service';

import * as OT from '@opentok/client';

import languagesJson from '@assets/languages.json';
import countryCodesJson from '@assets/countryCodes.json';

import { environment } from '../../../../../../environments/environment';
import { LoaderService } from '@services/support/loader.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  selectedAudioDeviceId: string = null;
  selectedAudioDeviceSub: Subscription = null;
  selectedVideoDeviceId: string = null;
  selectedVideoDeviceSub: Subscription = null;
  devicesChangedSub: Subscription = null;

  screenPublishing: boolean = false;
  screenPublishSub: Subscription = null;

  audios: any = [];
  videos: any = [];

  pAudio: boolean = true;
  private publishAudioSub: Subscription = null;
  pVideo: boolean = true;
  private publishVideoSub: Subscription = null;
  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;

  languages: { languageCode: string, displayName: string }[] = [];
  countryCodes: { number: string, flag: string }[] = [];

  selectedLanguageCode: string = null;
  selectedLanguageCode2: string = null;
  languageSub: Subscription = null;

  websiteOrigin: string = environment.design.useBaseUrlInsteadOfWindowOrigin ? environment.endPoints.base : window.location.origin;

  joinLinkId: string = null;
  joinLink: string = null;
  joinLinkAccountAllowed: boolean = false;
  joinLinkUserAllowed: boolean = false;
  creatingGuestAccess: boolean = false;
  creatingGuestAccessOnGoing: boolean = false;

  joinSMSAccountAllowed: boolean = true;
  joinSMSUserAllowed: boolean = true;

  joinSMS: boolean = true;
  joinSMSForm: boolean = false;
  joinWhatsapp: boolean = true;
  joinMail: boolean = true;

  sessionDataSub: Subscription = null;
  featuresSub: Subscription = null;
  userDataSub: Subscription = null;

  sendEnabled: boolean = false;
  smsAccountEnabled: boolean = true;
  addOnsSub: Subscription = null;
  mailToUrl: string;
  whatsappToUrl: string;
  qrcode: string = null;

  sendButtonClicked: boolean = false;
  resendPermissionEnabled: boolean = false;
  timerSubs: Subscription = null;

  // Login form binded data
  mobilePhone = {
    selectedCountry: null,
    number: null
  }

  numOfPhonesNumbersToStore = 3;
  savedPhoneNums = []

  fieldCode: string = '';
  enableFieldInviteButton: boolean = false;
  invitingViaFieldCode: boolean = false;

  constructor(
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private opentokService: OpentokService,
    private changeDetector: ChangeDetectorRef,
    private flashMessageService: FlashMessageService,
    private multilanguageService: MultilanguageService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.languages = languagesJson.languages;
    this.countryCodes = countryCodesJson.countrycodes.sort((a,b) => a.number > b.number ? 1 : -1);
    this.selectedAudioDeviceSub = this.opentokService.selectedAudioDevice.subscribe(ad => {
      this.selectedAudioDeviceId = ad;
    });
    this.selectedVideoDeviceSub = this.opentokService.selectedVideoDevice.subscribe(vd => {
      this.selectedVideoDeviceId = vd;
    });
    this.screenPublishSub = this.opentokService.screenPublishState.subscribe(p => {
      this.screenPublishing = p;
    });

    this.publishAudioSub = this.opentokService.publishAudio.subscribe(publishAudio => {
      this.pAudio = publishAudio;
      this.changeDetector.detectChanges();
    });
    this.publishVideoSub = this.opentokService.publishVideo.subscribe(publishVideo => {
      this.pVideo = publishVideo;
      this.changeDetector.detectChanges();
    });
    this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
      this.cameraMirror = mirror;
      this.changeDetector.detectChanges();
    });
    this.languageSub = this.multilanguageService.translationLanguage.subscribe(l => {
      this.selectedLanguageCode = l;
    });
    this.languageSub = this.multilanguageService.translationLanguage2.subscribe(l => {
      this.selectedLanguageCode2 = l;
    });

    this.sessionDataSub = this.roomSessionService.sessionData.subscribe(data => {
      if (data.join_link_id) {
        this.joinLinkId = data.join_link_id;
        if (this.authService.unifiedPanel) {
          this.joinLink = `${this.websiteOrigin}/joinroom?id=${data.join_link_id}&v2=true`;
        } else {
          this.joinLink = `${this.websiteOrigin}/joinroom?id=${data.join_link_id}`;
        }
        this.creatingGuestAccess = true;
        if (data.join_link_qr) {
          this.qrcode = data.join_link_qr;
        }
      }
    });
    
    this.featuresSub = this.authService.features.subscribe(features => {
      this.joinLinkAccountAllowed = features.joinwithlink;
    });
    this.userDataSub = this.authService.user.subscribe(user => {
      this.joinLinkUserAllowed = user.allow_join_link;
    });

    this.addOnsSub = combineLatest([this.authService.addOns, this.authService.user]).subscribe(([addOns, userData]) => {
      this.smsAccountEnabled = addOns.smssending && userData && userData.role === 'expert';
    });

    this.getUserMedia();
    this.devicesChangedSub = fromEvent(navigator.mediaDevices, 'devicechange')
    .subscribe(() => this.getUserMedia());    

    if (this.authService.currentUser) {
      const savedPhones = localStorage.getItem("phoneNums-"+this.authService.currentUser.id);
      if (savedPhones) {
        this.savedPhoneNums = JSON.parse(savedPhones)
        this.mobilePhone.selectedCountry = this.savedPhoneNums[0].selectedCountry;
      }
    }
  }

  cameraMirrorChanged(mirror: boolean) {
    this.opentokService.changeCameraMirror(mirror);
  }

  async getUserMedia() {
    const devices = await (new Promise<OT.Device[]>((resolve, reject) => {
      OT.getDevices((error, devices) => {
        if (error) {
          reject(error);
        } else {
          resolve(devices);
        }
      });
    }));

    //Implemented due to Firefox Android device list label issue
    devices.filter(d => d.kind === "audioInput").forEach((d, i) => {
      d.label = !d.label ? `Device ${i}` : d.label
    })
    devices.filter(d => d.kind === "videoInput").forEach((d, i) => {
      d.label = !d.label ? `Device ${i}` : d.label
    })
    this.audios = devices.filter(d => d.kind === "audioInput");
    this.videos = devices.filter(d => d.kind === "videoInput");
  }

  onAudioDeviceSelected(device: OT.Device) {
    this.opentokService.changeAudioDevice(device.deviceId);
  }

  onVideoDeviceSelected(device: OT.Device) {
    this.opentokService.changeVideoDevice(device.deviceId);
  }

  joinLinkCopied(event) {
    this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.LINK_COPIED', { cssClass: 'alert-success' });
  }

  onToggleAudio() {
    this.opentokService.toggleAudio();
  }

  onToggleVideo() {
    this.opentokService.toggleVideo();
  }

  ngOnDestroy() {
    if (this.selectedAudioDeviceSub) { this.selectedAudioDeviceSub.unsubscribe() }
    if (this.selectedVideoDeviceSub) { this.selectedVideoDeviceSub.unsubscribe() }
    if (this.devicesChangedSub) { this.devicesChangedSub.unsubscribe() }
    if (this.screenPublishSub) { this.screenPublishSub.unsubscribe() }
    if (this.publishAudioSub) { this.publishAudioSub.unsubscribe() }
    if (this.publishVideoSub) { this.publishVideoSub.unsubscribe() }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
    if (this.languageSub) { this.languageSub.unsubscribe() }
    if (this.sessionDataSub) { this.sessionDataSub.unsubscribe() }
    if (this.featuresSub) { this.featuresSub.unsubscribe() }
    if (this.addOnsSub) { this.addOnsSub.unsubscribe() }
    if (this.userDataSub) { this.userDataSub.unsubscribe() }
    if (this.timerSubs) { this.timerSubs.unsubscribe() }
  }

  onLangChange(language: any) {
    this.multilanguageService.changePreferedTranslationLang(language.languageCode)
  }

  onLangChange2(language: any) {
    this.multilanguageService.changePreferedTranslationLang2(language.languageCode)
  }

  generateMailToUrl() {
    if (this.joinLink) {
      const name = environment.design.addRemoteToName ? (environment.design.appName+" Remote") : environment.design.appName;
      const title = `${name} Session Invitation`;
      if (this.qrcode) {
        var body = `You are invited to ${name} Session: ${this.joinLink}\nQR Code for smart glasses: ${this.qrcode}`;
      } else {
        var body = `You are invited to ${name} Session: ${this.joinLink}`;
      }
      this.mailToUrl = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}`;
    }
  }

  generateWhatsappToUrl() {
    if (this.joinLink) {
      const name = environment.design.addRemoteToName ? (environment.design.appName+" Remote") : environment.design.appName;
      if (this.qrcode) {
        var body = `You are invited to ${name} Session: ${this.joinLink}\nQR Code for smart glasses: ${this.qrcode}`;
      } else {
        var body = `You are invited to ${name} Session: ${this.joinLink}`;
      }
      this.whatsappToUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(body)}`;
    }
  }

  onSMSGenerating() {
    if (!this.smsAccountEnabled) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.SMS_LICENSE_PERMISSION', { cssClass: 'alert-danger' })
      return;
    }

    this.joinSMS = true;
    this.joinSMSForm = true;
    this.joinWhatsapp = false;
    this.joinMail = false;
  }

  onSMSRegenerating() {
    if (!this.smsAccountEnabled) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.SMS_LICENSE_PERMISSION', { cssClass: 'alert-danger' })
      return;
    }

    if (!this.resendPermissionEnabled) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.RESHARE', { cssClass: 'alert-danger' })
      return;
    }

    this.joinSMS = true;
    this.joinSMSForm = true;
    this.joinWhatsapp = false;
    this.joinMail = false;
    
    this.onSendSMS();
  }

  onWhatsappGenerating() {
    this.joinSMS = false;
    this.joinSMSForm = false;
    this.joinWhatsapp = true;
    this.joinMail = false;

    this.generateWhatsappToUrl();
  }

  onMailGenerating() {
    this.joinSMS = false;
    this.joinSMSForm = false;
    this.joinWhatsapp = false;
    this.joinMail = true;

    this.generateMailToUrl();
  }

  onSendSMS() {
    if (!this.smsAccountEnabled) {
      return;
    }

    const name = environment.design.addRemoteToName ? (environment.design.appName+" Remote") : environment.design.appName;
    if (this.qrcode) {
      var smsContent = `You are invited to ${name} Session: ${this.joinLink}\nQR Code for smart glasses: ${this.qrcode}`;
    } else {
      var smsContent = `You are invited to ${name} Session: ${this.joinLink}.`;
    }

    this.loaderService.show();
    this.roomSessionService.sendJoinRoomLinkSMS(this.mobilePhone.selectedCountry, this.mobilePhone.number, this.joinLinkId, smsContent)
      .then(() => {
        this.updateSavedPhoneNumbers();
        this.mobilePhone.number = "";
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.SMS_SEND', { cssClass: 'alert-success' });
        this.sendButtonClicked = true;
        this.resendPermissionEnabled = false;
        this.timerSubs = timer(60000).subscribe(() => {
          this.resendPermissionEnabled = true;
        })
        this.sendEnabled = false;
      })
      .catch(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.error === 'sms-limit-reached') {
            this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.SMS_LIMIT_REACHED', { cssClass: 'alert-danger' });
          } else {
            this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.ERROR_OCCURED', { cssClass: 'alert-danger' });
          }
        } else {
          this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.ERROR_OCCURED', { cssClass: 'alert-danger' });
        }
      })
      .finally(() => {
        this.loaderService.hide();
      });
  }

  onMobilePhoneChange() {
    if (this.mobilePhone.selectedCountry && this.mobilePhone.number) {
      if (this.mobilePhone.number.toString().length >= 4 && this.mobilePhone.number.toString().length <= 20) {
        this.sendEnabled = true;
      } else {
        this.sendEnabled = false;
      }
    } else {
      this.sendEnabled = false;
    }
  }

  numberOnly(event: any) {
    if (!event) { 
      return false; 
    }

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  onPhoneNumClick(phoneNum: any) {
    this.mobilePhone.selectedCountry = phoneNum.selectedCountry;
    this.mobilePhone.number = phoneNum.number;
    this.sendEnabled = true;
  }

  isPhoneNumsSame(num1: any, num2: any){
    if (num1.selectedCountry === num2.selectedCountry && num1.number === num2.number) { return true }
    return false;
  }

  updateSavedPhoneNumbers() {
    let currentPhoneNums = [];

    if (this.authService.currentUser) {
      const currentPhones = localStorage.getItem("phoneNums-"+this.authService.currentUser.id);
      if (currentPhones){ currentPhoneNums = JSON.parse(currentPhones) }
    }

    for (let i=0; i < currentPhoneNums.length; i++) {
      if (this.isPhoneNumsSame(this.mobilePhone, currentPhoneNums[i])){
        currentPhoneNums.splice(i,1);
        break;
      }
    }
    currentPhoneNums.unshift(Object.assign({}, this.mobilePhone));
    if (currentPhoneNums.length > this.numOfPhonesNumbersToStore) { currentPhoneNums.pop() }

    if (this.authService.currentUser) {
      localStorage.setItem("phoneNums-"+this.authService.currentUser.id, JSON.stringify(currentPhoneNums));
    }
    this.savedPhoneNums = currentPhoneNums;
  }

  onCreateGuestAccess() {
    this.creatingGuestAccess = false;
    this.creatingGuestAccessOnGoing = true;

    if (this.joinLinkAccountAllowed) {
      if (this.joinLinkUserAllowed) {
        this.roomSessionService.generateJoinRoomLink()
          .then(result => {
            if (this.authService.unifiedPanel) {
              this.joinLink = `${this.websiteOrigin}/joinroom?id=${result.link_id}&v2=true`;
            } else {
              this.joinLink = `${this.websiteOrigin}/joinroom?id=${result.link_id}`;
            }
            this.creatingGuestAccess = true;
            this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.LINK_GENERATED', { cssClass: 'alert-success' });
          })
          .catch(error => {
            this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.CANNOT_CREATE_GUEST_ACCESS');
          })
          .finally(() => {
            this.creatingGuestAccessOnGoing = false;
          })
      } else {
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.NO_LINK_PERMISSION', { timeout: 6000 });
      }
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE', { timeout: 6000 });
    }
  }

  onFieldCodeChange() {
    this.enableFieldInviteButton = false;
    if (this.fieldCode) {
      const fieldCodeLength = this.fieldCode.length;
      if (fieldCodeLength === 6) {
        this.enableFieldInviteButton = true;
      } else {
        this.enableFieldInviteButton = false;
      }
    }
  }

  inviteFieldUserToSession() {
    if (!this.joinLinkAccountAllowed) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE', { timeout: 6000 });
      return;
    } else if (!this.joinLinkUserAllowed) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.NO_LINK_PERMISSION', { timeout: 6000 });
      return;
    } else if (!this.fieldCode) {
      return;
    } else if (this.fieldCode) {
      if (this.fieldCode.length !== 6) {
        return;
      }
    }

    this.enableFieldInviteButton = false;
    this.invitingViaFieldCode = true;

    const currentRoomId = this.roomSessionService.currentRoomId;
    const sessionId = this.roomSessionService.currentSessionId;

    this.authService.inviteFieldUser(this.fieldCode, currentRoomId, sessionId)
      .then(res => {
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.INVITATION_SENT', { cssClass: 'alert-info' });
      })
      .catch(err => {
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.SETTINGS.INVITATION_COULD_NOT_SENT', { cssClass: 'alert-danger' });
      })
      .finally(() => {
        this.invitingViaFieldCode = false;
        this.fieldCode = null;
      });
  }
}
