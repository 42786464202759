<nav class="navbar navbar-light navbar-default navbar-static-top navbar-expand px-0" *ngIf="data?.guest">
    <div class="container-fluid">
        <a *ngIf="('design' | env).logoText" class="navbar-brand px-3 mr-0">
            <img class="logo" [src]="('design' | env).logo">
            <span class="ml-2">{{ ('design' | env).logoText }}</span>
        </a>
        <a *ngIf="!('design' | env).logoText" class="navbar-only-logo-brand px-3 mr-0">
            <img class="logo" [class.wide]="('design' | env).wideLogo" [src]="('design' | env).logo">
        </a>
        <a class="navbar-only-logo-brand" *ngIf="secondLogo">
            <a class="for-label navbar-only-logo-brand text-muted">for</a>
            <img class="logo-second" [src]="secondLogo">
        </a>
    </div>
</nav>

<div class="session-rating-wrapper px-md-5 px-4 py-5" #list>
    <div *ngFor="let qu of questions; let qi = index" class="question mt-5">
        <h6 class="rating-message">{{ 'APP.MAIN.SESSION_RATING2.QUESTIONS.'+qu.key | translate }}</h6>
        <div class="ratings mt-3">
            <div *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" class="rating-box box border border-secondary p-2 ml-1"
                    (click)="onQuestionRating(qi, i)" [ngClass]="{'bg-dark text-light': qu.rate === i}">
                {{ i }}
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center mt-5">
        <button class="btn btn-primary rounded-0" [disabled]="!allQuestionsRated" (click)="onSubmitRates()">
            {{ 'APP.MAIN.SESSION_RATING2.SUBMIT' | translate }}
        </button>
    </div>
</div>