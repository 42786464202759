<div class="chat-container">
    <div class="chat-list-area">
        <div class="chat-message mt-1" *ngFor="let message of logMessages; let i = index;">

            <div class="text-message left" *ngIf="message.type === 'text'" [ngClass]="{'left': !message.details.own_message, 'right': message.details.own_message}">
                <div class="owner-info my-1" *ngIf="message.details.show_owner">
                    {{ message.owner_info }}
                </div>
                <div class="chat-bubble">
                    <div class="chat-text text-left text-muted" [innerHTML]="message.content | linkify:{target: '_blank'}"></div>
                    <div class="chat-translation text-left mt-2">{{ message.translation }}</div>
                    <div class="chat-time text-right text-muted">{{ message.create_time | date:'HH:mm' }}</div>
                </div>
            </div>
        </div>
    </div>
</div>