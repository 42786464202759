import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.scss']
})
export class TranscriptionComponent implements OnInit, OnDestroy {

  logMessages = []
  logsSub: Subscription = null

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private roomSessionService: RoomSessionService
  ) { }

  ngOnInit(): void {

    this.logsSub = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${this.roomSessionService.currentRoomId}/${this.roomSessionService.currentSessionId}/transcriptions`, "child_added")
    .subscribe(snp => {
      const log = snp.data;
      log.id = snp.key;

      const prevMessage = this.logMessages[this.logMessages.length-1];

      if (log.owner === this.authService.currentUser.id) {
        log.details = {
          own_message: true
        }
      } else {
        log.details = {
          own_message: false
        }
      }
      log.details.show_owner = this.logMessages.length === 0 || this.logMessages.length > 0 && prevMessage.owner !== log.owner;
      this.logMessages.push(log);
    });

    /*this.logMessages.push({
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "text",
      content: "deneme",
      details: {
        own_message: true,
        show_owner: true
      },
      translation: "test"
    })*/
  }

  ngOnDestroy() {
    if (this.logsSub) { this.logsSub.unsubscribe() }
  }
}
